import React from 'react'
import { PropTypes } from 'prop-types'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const AuthorizedHeader = (props) => {
  return (
    <>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="12">
                <Breadcrumb
                  className="text-white d-none d-md-inline-flex ml-lg-4" listClassName="breadcrumb-links">
                  <BreadcrumbItem>
                    <FontAwesomeIcon icon={faHome}/>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {props.name}
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {props.parentName}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

AuthorizedHeader.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired
}

export default AuthorizedHeader
