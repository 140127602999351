// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Card, CardFooter, CardHeader, Container, Row, Table } from 'reactstrap'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import Pagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faEye } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import DatagridCreatedAtUpdatedAt from 'components/Utils/DatagridCreatedAtUpdatedAt'
import DatetimeFormatV2 from 'components/Utils/DatetimeFormatV2'
import PaginationInfo from 'components/Utils/PaginationInfo'
import DaftarIsiBerkasFilterAndSort from './DaftarIsiBerkasFilterAndSort'
import LoadingContent from 'components/Utils/LoadingContent'

const ArsipUKDaftarIsiBerkasDataGrid = (props) => {
  ArsipUKDaftarIsiBerkasDataGrid.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
    pageSize: 7,
    data: null,
    filter: {}
  })

  useEffect(() => {
    getData(props.token, 1, {})
  }, [props])

  const getData = (token, page, filter) => {
    // console.log(filter)
    let url = `/unit-kearsipan/isi-berkas?page=${page}`
    if (_.isObject(filter)) {
      // unitPengolah
      if (_.isObject(filter.unitPengolah)) {
        url += `&unit-pengolah=${filter.unitPengolah.value}`
      }
      // uk-daftar-berkas
      if (_.isObject(filter.berkas)) {
        url += `&uk-daftar-berkas=${filter.berkas.value}`
      }
      // search
      if (_.has(filter, 'search')) {
        if (!_.isEmpty(filter.search)) {
          url += `&search=${filter.search}`
        }
      }
      // orderBy
      if (_.has(filter, 'orderBy') && _.has(filter, 'orderOrientation')) {
        if (_.isObject(filter.orderBy) && _.isObject(filter.orderOrientation)) {
          url += `&sort={"${filter.orderBy.value}":"${filter.orderOrientation.value}"}`
        }
      }
      // pageSize
      if (_.has(filter, 'pageSize')) {
        if (_.isObject(filter.pageSize)) {
          setState((prevProps) => ({
            ...prevProps,
            itemsCountPerPage: filter.pageSize.value
          }))
          url += `&page-size=${filter.pageSize.value}`
        }
      } else {
        url += `&page-size=${state.pageSize}`
      }
    }
    // console.log(url)
    Axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          data: res.data._embedded.unit_kearsipan_isi_berkas,
          activePage: res.data.page,
          itemsCountPerPage: res.data.page_size,
          totalItemsCount: res.data.total_items
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const handlePageChange = (pageNumber) => {
    setState((prevProps) => ({
      ...prevProps,
      activePage: pageNumber
    }))
    getData(props.token, pageNumber, state.filter)
  }

  const handleFilterApply = (filterAndSort) => {
    const filter = state.filter
    Object.assign(filter, filterAndSort)
    setState((prevProps) => ({
      ...prevProps,
      filter: filter
    }))
    getData(props.token, 1, filter)
  }

  if (_.isNull(state.data)) {
    return <LoadingContent />
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faArchive} data={['arsip unit kearsipan', 'daftar berkas']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <DaftarIsiBerkasFilterAndSort token={props.token} onApply={handleFilterApply}/>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th width="1%">
                    Op.
                  </th>
                  <th scope="col">
                    Unit Kerja <br/>
                    Unit Pengolah
                  </th>
                  <th scope="col">
                    Berkas <br/>(Nomor Folder / Judul)
                  </th>
                  <th scope="col">
                    Nomor<br/>Dokumen
                  </th>
                  <th scope="col">
                    Tingkat<br/>Perkembangan
                  </th>
                  <th scope="col">
                    Judul
                  </th>
                  <th scope="col">
                    Tanggal <br/>Dokumen
                  </th>
                  <th scope="col">
                    <small>Created At<br/>Updated At</small>
                  </th>
                </tr>
                </thead>
                <tbody className="list">
                {state.data.length === 0
                  ? <tr>
                    <td colSpan={5}><code>...</code></td>
                  </tr>
                  : null}
                {state.data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <FontAwesomeIcon icon={faEye}/>
                      </td>
                      <td>
                        {_.isNull(d.unitKerja) ? null : d.unitKerja.name} /<br/>
                        {_.isNull(d.unitPengolah) ? null : d.unitPengolah.name}
                      </td>
                      <td>{_.isNull(d.daftarBerkas) ? null : dataGridBerkas(d.daftarBerkas)}</td>
                      <td>{d.nomorDokumen}</td>
                      <td>{_.isNull(d.tingkatPerkembangan) ? null : d.tingkatPerkembangan.name}</td>
                      <td>{d.judul}</td>
                      <td><DatetimeFormatV2 value={d.tanggalDokumen}/></td>
                      <td><DatagridCreatedAtUpdatedAt createdAt={d.createdAt} updatedAt={d.updatedAt}/></td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    innerClass="pagination justify-content-end mb-0"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="⟨"
                    nextPageText="⟩"
                    firstPageText="«"
                    lastPageText="»"
                    activePage={state.activePage}
                    itemsCountPerPage={state.itemsCountPerPage}
                    totalItemsCount={state.totalItemsCount}
                    pageRangeDisplayed={state.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                  <PaginationInfo
                    activePage={state.activePage}
                    totalItemsCount={state.totalItemsCount}
                    itemsCountPerPage={state.itemsCountPerPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

const dataGridBerkas = (daftarBerkas) => {
  if (!_.isNull(daftarBerkas)) {
    return <>{daftarBerkas.nomorFolder} <br/> {daftarBerkas.judul}</>
  }
  return null
}

export default ValidationAuthorized(ArsipUKDaftarIsiBerkasDataGrid)
