// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'
import DatetimeFormatV2 from './Utils/DatetimeFormatV2'

const ArsipUkAsalBerkas = (props) => {
  ArsipUkAsalBerkas.propTypes = {
    data: PropTypes.object.isRequired
  }

  return (
    <>
      {/* {console.log(props.data)} */}
      <Table>
        <tbody>
        <tr>
          <td className="text-right text-capitalize col-md-3">short id :</td>
          <td className="text-left">{props.data.shortid}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">judul :</td>
          <td className="text-left">{props.data.judul}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">Tanggal Proses Pindah :</td>
          <td className="text-left"><DatetimeFormatV2 value={props.data.tanggalProsesPindah}/></td>
        </tr>
        </tbody>
      </Table>
    </>
  )
}
export default ArsipUkAsalBerkas
