// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Container, Form, Input, Row, Table } from 'reactstrap'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import _ from 'lodash'
import Toastr from 'toastr'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faArchive, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ArsipUkMetadata from 'components/ArsipUkMetadata'
import ArsipUkIsiBerkas from 'components/ArsipUkIsiBerkas'
import ArsipUkAsalBerkas from 'components/ArsipUkAsalBerkas'

const DaftarBerkasForm = (props) => {
  DaftarBerkasForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    data: null,
    rak: null,
    rakOptions: [],
    nomorFolderUk: '',
    isNomorFolderUkValid: true
  })

  useEffect(() => {
    getRak()
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
  }, [props])

  const getRak = () => {
    Axios.get('/unit-kearsipan/rak-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          rakOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (id) => {
    Axios.get(`/unit-kearsipan/daftar-berkas/${id}?isi-berkas=true&asal-berkas=true`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          data: res.data,
          nomorFolderUk: _.isNull(res.data.nomorFolderUk) ? '' : res.data.nomorFolderUk,
          rak: _.isNull(res.data.rak)
            ? null
            : {
                code: res.data.rak.code,
                label: res.data.rak.name,
                value: res.data.rak.id
              }
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeRak = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      rak: obj
    }))
  }

  const handleChangeNomorFolderUk = (obj) => {
    const nomorFolderUk = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      nomorFolderUk
    }))
    // validation
    Toastr.info('validasi...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/daftar-berkas/nomor-folder-uk/validation',
      data: {
        uuid: state.data.id,
        nomorFolderUk: nomorFolderUk
      }
    })
      .then((res) => {
        // console.log(res.data.detail)
        if (res.data.detail) {
          Toastr.success('ok')
          setState((prevProps) => ({
            ...prevProps,
            isNomorFolderUkValid: true
          }))
        } else {
          Toastr.error('sudah terpakai')
          setState((prevProps) => ({
            ...prevProps,
            isNomorFolderUkValid: false
          }))
        }
      })
      .catch(err => {
        console.log(err)
        // Toastr.error('Gagal')
      })
  }

  const onSubmit = () => {
    Toastr.info('Simpan...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'PATCH',
      url: `/unit-kearsipan/daftar-berkas/${state.data.id}`,
      data: {
        rak: _.isObject(state.rak) ? state.rak.value : '',
        nomorFolderUk: state.nomorFolderUk
      }
    })
      .then(() => {
        Toastr.success('Sukses')
      })
      .catch(() => {
        Toastr.error('Gagal')
      })
  }

  if (_.isNull(state.data)) {
    return <></>
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faArchive} data={['arsip unit kearsipan', 'daftar berkas']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader>
                <Link className="btn btn-primary" to="/authorized/arsip-uk/daftar-berkas">
                  <i className="fas fa-bars"/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                <Tabs>
                  <TabList>
                    <Tab>Metadata</Tab>
                    <Tab>Isi Berkas</Tab>
                    <Tab>Asal Berkas (Usul Pindah)</Tab>
                  </TabList>
                  <TabPanel>
                    <Form>
                      <Table>
                        <tbody>
                        <tr>
                          <td className="text-right text-capitalize col-md-3">rak :</td>
                          <td className="text-left">
                            <Select
                              value={state.rak}
                              onChange={handleChangeRak}
                              options={state.rakOptions}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right text-capitalize">nomor folder (UK) :</td>
                          <td className="text-left">
                            <Input
                              value={state.nomorFolderUk}
                              onChange={handleChangeNomorFolderUk}
                              placeholder="Nama"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right"/>
                          <td className="text-left">
                            <Button
                              disabled={!state.isNomorFolderUkValid}
                              color='primary'
                              onClick={onSubmit}
                            ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </Form>
                    <ArsipUkMetadata data={state.data}/>
                  </TabPanel>
                  <TabPanel>
                    <ArsipUkIsiBerkas data={state.data.isiBerkas}/>
                  </TabPanel>
                  <TabPanel>
                    <ArsipUkAsalBerkas data={state.data.asalBerkas}/>
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(DaftarBerkasForm)
