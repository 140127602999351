// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'
import IconBoolean from './Utils/IconBoolean'

const UsulMusnahMetadata = (props) => {
  UsulMusnahMetadata.propTypes = {
    data: PropTypes.object.isRequired
  }

  return (
    <Table>
      <tbody>
      <tr>
        <td className="text-right col-md-1">ID :</td>
        <td><code className="text-lg">{props.data.shortId}</code></td>
      </tr>
      <tr>
        <td className="text-right text-capitalize">judul :</td>
        <td><h5>{props.data.judul}</h5></td>
      </tr>
      <tr>
        <td className="text-right text-capitalize">deskripsi :</td>
        <td><h5>{props.data.deskripsi}</h5></td>
      </tr>
      <tr>
        <td className="text-right text-capitalize">dokumen surat usulan :</td>
        <td>
          <IconBoolean value={props.data.checkDokumenSuratUsulan}/>
        </td>
      </tr>
      <tr>
        <td className="text-right text-capitalize">dokumen kesepakatan :</td>
        <td>
          <IconBoolean value={props.data.checkDokumenKesepakatan}/>
        </td>
      </tr>
      <tr>
        <td className="text-right text-capitalize">dokumen berita acara pemusnahan :</td>
        <td>
          <IconBoolean value={props.data.checkDokumenBeritaAcaraPemusnahan}/>
        </td>
      </tr>
      <tr>
        <td className="text-right text-capitalize">status pemusnahan :</td>
        <td>
          <IconBoolean value={props.data.apakahSudahMusnah}/>
        </td>
      </tr>
      </tbody>
    </Table>
  )
}

export default UsulMusnahMetadata
