import React from 'react'

class AuthHeader extends React.Component {
  render () {
    return (
      <>
        <div className="header py-7 py-lg-8 pt-lg-9">
        </div>
      </>
    )
  }
}

export default AuthHeader
