import React, { useEffect, useState } from 'react'
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import _, { debounce } from 'lodash'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faPencilAlt, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import Axios from 'helpers/Axios'
import IconBoolean from 'components/Utils/IconBoolean'
import PaginationInfo from 'components/Utils/PaginationInfo'
import DatagridCreatedAtUpdatedAt from 'components/Utils/DatagridCreatedAtUpdatedAt'
import LoadingContent from 'components/Utils/LoadingContent'

const KondisiArsipDataGrid = (props) => {
  const [state, setState] = useState({
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
    pageSize: 7,
    data: null,
    search: '',
    filter: {}
  })

  useEffect(() => {
    // console.log('useEffect first load')
    getData(props.token, 1, null)
  }, [props])

  const getData = (token, page, filter) => {
    // console.log(filter)
    let url = `/kondisi-arsip?page=${page}`
    if (_.isObject(filter)) {
      // search
      if (_.has(filter, 'search')) {
        if (!_.isEmpty(filter.search)) {
          url += `&search=${filter.search}`
        }
      }
    }
    // console.log(url)
    Axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data);
        setState((prevProps) => ({
          ...prevProps,
          data: res.data._embedded.kondisi_arsip,
          activePage: res.data.page,
          itemsCountPerPage: res.data.page_size,
          totalItemsCount: res.data.total_items
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const handleInputChangeSearch = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      search: obj.target.value,
      filter: { search: obj.target.value }
    }))
    const debouncedSave = debounce(() => getData(props.token, 1, { search: obj.target.value }), 1000)
    debouncedSave()
  }

  const handlePageChange = (pageNumber) => {
    setState((prevProps) => ({
      ...prevProps,
      activePage: pageNumber
    }))
    getData(props.token, pageNumber, state.filter)
  }

  if (_.isNull(state.data)) {
    return <LoadingContent />
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faCogs} data={['master', 'kondisi arsip']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <Form className="navbar-search form-inline mr-sm-3">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group input-group-merge">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FontAwesomeIcon icon={faSearch}/>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search"
                            type="text"
                            value={state.search}
                            onChange={handleInputChangeSearch}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col>
                    <Link
                      className="btn btn-primary float-right"
                      to="/authorized/add/master/kondisi-arsip">
                      <FontAwesomeIcon icon={faPlus}/>
                      <span className="btn-inner--text">Tambah</span>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th width="1%">
                    Op.
                  </th>
                  <th scope="col">
                    Nama
                  </th>
                  <th scope="col">
                    Deskripsi
                  </th>
                  <th scope="col">
                    Aktif
                  </th>
                  <th scope="col">
                    <small>Created At<br />Updated At</small>
                  </th>
                </tr>
                </thead>
                <tbody className="list">
                {state.data.length === 0
                  ? <tr>
                    <td colSpan={5}><code>...</code></td>
                  </tr>
                  : null}
                {state.data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <Link
                          to={`/authorized/update/master/kondisi-arsip/${d.id}`}>
                          <FontAwesomeIcon icon={faPencilAlt}/>
                        </Link>
                      </td>
                      <td>{d.name}</td>
                      <td>{d.description}</td>
                      <td><IconBoolean value={d.isActive}/></td>
                      <td><DatagridCreatedAtUpdatedAt createdAt={d.createdAt} updatedAt={d.updatedAt} /></td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    innerClass="pagination justify-content-end mb-0"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="⟨"
                    nextPageText="⟩"
                    firstPageText="«"
                    lastPageText="»"
                    activePage={state.activePage}
                    itemsCountPerPage={state.itemsCountPerPage}
                    totalItemsCount={state.totalItemsCount}
                    pageRangeDisplayed={state.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                  <PaginationInfo
                    activePage={state.activePage}
                    totalItemsCount={state.totalItemsCount}
                    itemsCountPerPage={state.itemsCountPerPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

KondisiArsipDataGrid.propTypes = {
  token: PropTypes.string.isRequired
}

export default ValidationAuthorized(KondisiArsipDataGrid)
