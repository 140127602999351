// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorClosed, faEye, faPlus, faTruck } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import IconBoolean from 'components/Utils/IconBoolean'
import DatagridCreatedAtUpdatedAt from 'components/Utils/DatagridCreatedAtUpdatedAt'
import PaginationInfo from 'components/Utils/PaginationInfo'
import UsulSerahDetail from 'components/UsulSerahDetail'
import LoadingContent from 'components/Utils/LoadingContent'

const UsulSerahDataGrid = (props) => {
  UsulSerahDataGrid.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
    pageSize: 7,
    data: null,
    filter: {},
    uidSelected: null
  })

  useEffect(() => {
    getData(1, {})
  }, [props])

  const getData = (page, filter) => {
    // console.log(filter)
    let url = `/unit-kearsipan/penyusutan/usul-serah?page=${page}`
    if (_.isObject(filter)) {
      // search
      if (_.has(filter, 'search')) {
        if (!_.isEmpty(filter.search)) {
          url += `&search=${filter.search}`
        }
      }
      // orderBy
      if (_.has(filter, 'orderBy') && _.has(filter, 'orderOrientation')) {
        if (_.isObject(filter.orderBy) && _.isObject(filter.orderOrientation)) {
          url += `&sort={"${filter.orderBy.value}":"${filter.orderOrientation.value}"}`
        }
      }
      // pageSize
      if (_.has(filter, 'pageSize')) {
        if (_.isObject(filter.pageSize)) {
          setState((prevProps) => ({
            ...prevProps,
            itemsCountPerPage: filter.pageSize.value
          }))
          url += `&page-size=${filter.pageSize.value}`
        }
      } else {
        url += `&page-size=${state.pageSize}`
      }
    }
    // console.log(url)
    Axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          data: res.data._embedded.unit_kearsipan_penyusutan_usul_serah,
          activePage: res.data.page,
          itemsCountPerPage: res.data.page_size,
          totalItemsCount: res.data.total_items
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const handlePageChange = (pageNumber) => {
    setState((prevProps) => ({
      ...prevProps,
      activePage: pageNumber
    }))
    getData(pageNumber, state.filter)
  }

  const onModalShow = (e) => {
    const uidSelected = e.currentTarget.getAttribute('data-value-uid')
    // console.log(uidSelected)
    setState((prevProps) => ({
      ...prevProps,
      uidSelected
    }))
  }

  const handleApplyShowDetail = () => {
    setState((prevProps) => ({
      ...prevProps,
      uidSelected: null
    }))
  }

  const handleApplyDelete = () => {
    // Toastr.info('handleApplyDelete')
    getData(1, state.filter)
    setState((prevProps) => ({
      ...prevProps,
      uidSelected: null
    }))
  }

  if (_.isNull(state.data)) {
    return <LoadingContent />
  }

  return (
    <>
       {/* {console.log(state)} */}
      {_.isNull(state.uidSelected)
        ? null
        : <ShowDetail
          uid={state.uidSelected}
          token={props.token}
          onApplyShowDetail={handleApplyShowDetail}
          onApplyDelete={handleApplyDelete}
        />
      }
      <AuthorizedHeader2 icon={faTruck} data={['penyusutan', 'usul serah']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  {/* <UsulserahFilterAndSort token={props.token} onApply={handleFilterApply}/> */}
                  <Col>
                    <Link
                      className="btn btn-primary float-right"
                      to="/authorized/penyusutan/add/usul-serah">
                      <FontAwesomeIcon icon={faPlus}/>
                      <span className="btn-inner--text">Tambah</span>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th width="1%">
                    Op.
                  </th>
                  <th width="1%">
                    ID
                  </th>
                  <th scope="col">
                    Judul
                  </th>
                  <th scope="col">
                    Persyaratan Dokumen<br/>Usulan / Kesepakatan / BA Peserahan
                  </th>
                  <th scope="col">
                    Status <br/>Peserahan
                  </th>
                  <th scope="col">
                    <small>Created At<br/>Updated At</small>
                  </th>
                </tr>
                </thead>
                <tbody className="list">
                {state.data.length === 0
                  ? <tr>
                    <td colSpan={5}><code>...</code></td>
                  </tr>
                  : null}
                {state.data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td className="table-actions">
                        <FontAwesomeIcon
                          style={{ cursor: 'pointer' }}
                          icon={faEye}
                          data-value-i={i}
                          data-value-uid={d.id}
                          onClick={onModalShow}
                        />
                      </td>
                      <td><code>{d.shortId}</code></td>
                      <td>{d.judul}</td>
                      <td>
                        <IconBoolean value={d.checkDokumenSuratUsulan}/> / <IconBoolean
                        value={d.checkDokumenKesepakatan}/> / <IconBoolean value={d.checkDokumenBeritaAcaraPenyerahan}/>
                      </td>
                      <td>
                        <IconBoolean value={d.apakahSudahSerah}/>
                      </td>
                      <td><DatagridCreatedAtUpdatedAt createdAt={d.createdAt} updatedAt={d.updatedAt}/></td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    innerClass="pagination justify-content-end mb-0"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="⟨"
                    nextPageText="⟩"
                    firstPageText="«"
                    lastPageText="»"
                    activePage={state.activePage}
                    itemsCountPerPage={state.itemsCountPerPage}
                    totalItemsCount={state.totalItemsCount}
                    pageRangeDisplayed={state.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                  <PaginationInfo
                    activePage={state.activePage}
                    totalItemsCount={state.totalItemsCount}
                    itemsCountPerPage={state.itemsCountPerPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulSerahDataGrid)

const ShowDetail = ({ uid, token, onApplyShowDetail }) => {
  ShowDetail.propTypes = {
    uid: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    onApplyShowDetail: PropTypes.func,
    onApplyDelete: PropTypes.func
  }

  const [state, setState] = useState({
    isOpen: true,
    data: null
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    Axios.get(`/unit-kearsipan/penyusutan/usul-serah/${uid}?include-dokumen=true&include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const toggleView = () => {
    setState((prevProps) => ({
      ...prevProps,
      isOpen: !state.isOpen
    }))
    onApplyShowDetail()
  }

  if (_.isNull(state.data)) {
    return null
  }

  return (
    <>
      {/* {console.log(state)} */}
      <Modal size={'lg'} isOpen={state.isOpen}>
        <ModalHeader className="text-capitalize">usul serah detail</ModalHeader>
        <ModalBody>
          <UsulSerahDetail token={token} data={state.data}/>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggleView}
            color="primary"><FontAwesomeIcon icon={faDoorClosed}/> Tutup</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
