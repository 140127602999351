// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import Toastr from 'toastr'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Select from 'react-select'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader from 'components/Headers/AuthorizedHeader'
import PenyusutanUsulMusnahSerahDaftarBerkas from 'components/PenyusutanUsulMusnahSerahDaftarBerkas'
import PenyusutanUsulMusnahSerahDokumen from 'components/PenyusutanUsulMusnahSerahDokumen'
import LoadingContent from 'components/Utils/LoadingContent'

const UsulMusnahFormMetadata = (props) => {
  UsulMusnahFormMetadata.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    data: null,
    judul: '',
    deskripsi: '',
    checkDokumenSuratUsulan: null,
    checkDokumenKesepakatan: null,
    checkDokumenBeritaAcaraPemusnahan: null,
    checkDokumenOptions: [
      { label: 'Tersedia', value: 'true' },
      { label: 'Tidak Tersedia', value: 'false' }
    ],

    modalConfirmRemoveUsulMusnah: null,
    modalConfirmProsesUsulMusnah: null,
    isDeleted: false
  })

  useEffect(() => {
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
  }, [props])

  const getData = (id) => {
    Axios.get(`/unit-kearsipan/penyusutan/usul-musnah/${id}?include-dokumen=true&include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data,
          judul: response.data.judul,
          deskripsi: response.data.deskripsi,
          checkDokumenSuratUsulan: response.data.checkDokumenSuratUsulan
            ? { label: 'Tersedia', value: 'true' }
            : { label: 'Tidak Tersedia', value: 'false' },
          checkDokumenKesepakatan: response.data.checkDokumenKesepakatan
            ? { label: 'Tersedia', value: 'true' }
            : { label: 'Tidak Tersedia', value: 'false' },
          checkDokumenBeritaAcaraPemusnahan: response.data.checkDokumenBeritaAcaraPemusnahan
            ? { label: 'Tersedia', value: 'true' }
            : { label: 'Tidak Tersedia', value: 'false' }
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleChangeJudul = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      judul: obj.target.value
    }))
  }

  const handleChangeDeskripsi = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      deskripsi: obj.target.value
    }))
  }

  const handleChangeCheckDokumenSuratUsulan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      checkDokumenSuratUsulan: obj
    }))
  }

  const handleChangeCheckDokumenKesepakatan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      checkDokumenKesepakatan: obj
    }))
  }

  const handleChangeCheckDokumenBeritaAcaraPemusnahan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      checkDokumenBeritaAcaraPemusnahan: obj
    }))
  }

  const onSubmit = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'PATCH',
      url: `/unit-kearsipan/penyusutan/usul-musnah/${state.data.id}`,
      data: {
        judul: state.judul,
        deskripsi: state.deskripsi,
        checkDokumenSuratUsulan: _.isObject(state.checkDokumenSuratUsulan)
          ? state.checkDokumenSuratUsulan.value
          : '',
        checkDokumenKesepakatan: _.isObject(state.checkDokumenKesepakatan)
          ? state.checkDokumenKesepakatan.value
          : '',
        checkDokumenBeritaAcaraPemusnahan: _.isObject(state.checkDokumenBeritaAcaraPemusnahan)
          ? state.checkDokumenBeritaAcaraPemusnahan.value
          : ''
      }
    })
      .then(() => {
        Toastr.info('Sukses...')
        getData(props.match.params.uid)
      })
      .catch(() => {
        Toastr.error('Gagal!')
      })
  }

  const showModalRemoveUsulMusnah = () => {
    setState((prevProps) => ({
      ...prevProps,
      modalConfirmRemoveUsulMusnah: (
        <ReactBSAlert
          warning
          style={{
            display: 'block'
          }}
          title="Hapus Usul Musnah"
          onConfirm={onConfirmRemoveUsulMusnah}
          onCancel={onCancelRemoveUsulMusnah}
          confirmBtnCssClass="success"
          cancelBtnBsStyle="warning"
          confirmBtnText="Ya, Hapus"
          cancelBtnText="Batal"
          showCancel
          btnSize="">Anda yakin ?
        </ReactBSAlert>
      )
    }))
  }

  const onConfirmRemoveUsulMusnah = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/unit-kearsipan/penyusutan/usul-musnah/${state.data.id}`
    })
      .then(() => {
        Toastr.info('Sukses hapus usul musnah...')
        setState((prevProps) => ({
          ...prevProps,
          modalConfirmRemoveUsulMusnah: null,
          isDeleted: true
        }))
      })
      .catch(() => {
        Toastr.error('Gagal!')
      })
  }

  const onCancelRemoveUsulMusnah = () => {
    setState((prevProps) => ({
      ...prevProps,
      modalConfirmRemoveUsulMusnah: null
    }))
  }

  const showModalProsesUsulMusnah = () => {
    setState((prevProps) => ({
      ...prevProps,
      modalConfirmProsesUsulMusnah: (
        <ReactBSAlert
          warning
          style={{
            display: 'block'
          }}
          title="Proses Usul Musnah"
          onConfirm={onSubmitProsesUsulMusnah}
          onCancel={onCancelProsesUsulMusnah}
          confirmBtnCssClass="success"
          cancelBtnBsStyle="warning"
          confirmBtnText="Ya, Proses"
          cancelBtnText="Batal"
          showCancel
          btnSize="">Anda yakin ?
        </ReactBSAlert>
      )
    }))
  }

  const onSubmitProsesUsulMusnah = () => {
    console.log('onSubmitProsesUsulMusnah')
    setState((prevProps) => ({
      ...prevProps,
      modalConfirmProsesUsulMusnah: null
    }))
  }

  const onCancelProsesUsulMusnah = () => {
    setState((prevProps) => ({
      ...prevProps,
      modalConfirmProsesUsulMusnah: null
    }))
  }

  if (state.isDeleted) {
    return <Redirect to={'/authorized/penyusutan/usul-musnah'}/>
  }

  if (_.isNull(state.data)) {
    return <LoadingContent />
  }

  return (
    <>
       {/* {console.log(state)} */}
      {state.modalConfirmRemoveUsulMusnah}
      {state.modalConfirmProsesUsulMusnah}
      <AuthorizedHeader name="Penyusutan" parentName="Usul Musnah"/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-black-50 text-underline text-capitalize">metadata</h1>
              </CardHeader>
              <CardBody>
                <Tabs defaultIndex={0}>
                  <TabList>
                    <Tab>Metadata</Tab>
                    <Tab>Daftar Berkas</Tab>
                    <Tab>Dokumen</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="text-right">
                      <Button
                        disabled={state.data.apakahSudahMusnah}
                        color='danger'
                        onClick={showModalRemoveUsulMusnah}
                      ><FontAwesomeIcon icon={faTrash}/> Hapus</Button>
                    </div>
                    <Form>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3">
                          ID
                        </Label>
                        <Col md="8">
                          <h1><code>{state.data.shortId}</code></h1>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3" className="text-capitalize">
                          judul
                        </Label>
                        <Col md="8">
                          <Input
                            value={state.judul}
                            onChange={handleChangeJudul}
                            placeholder="Judul"
                            type="text"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3" className="text-capitalize">
                          deskripsi
                        </Label>
                        <Col md="8">
                          <Input
                            value={state.deskripsi}
                            onChange={handleChangeDeskripsi}
                            placeholder="Deskripsi"
                            type="text"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3" className="text-capitalize">
                          dokumen surat usulan
                        </Label>
                        <Col md="3">
                          <Select
                            value={state.checkDokumenSuratUsulan}
                            onChange={handleChangeCheckDokumenSuratUsulan}
                            options={state.checkDokumenOptions}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3" className="text-capitalize">
                          dokumen kesepakatan
                        </Label>
                        <Col md="3">
                          <Select
                            value={state.checkDokumenKesepakatan}
                            onChange={handleChangeCheckDokumenKesepakatan}
                            options={state.checkDokumenOptions}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3" className="text-capitalize">
                          dokumen berita acara pemusnahan
                        </Label>
                        <Col md="3">
                          <Select
                            value={state.checkDokumenBeritaAcaraPemusnahan}
                            onChange={handleChangeCheckDokumenBeritaAcaraPemusnahan}
                            options={state.checkDokumenOptions}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3">
                        </Label>
                        <Col md="4">
                          <Button
                            disabled={state.data.apakahSudahMusnah}
                            color='primary'
                            onClick={onSubmit}
                          ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                    <hr/>
                    <h2>Status Pemusnahan</h2>
                    <Alert color="warning">penjelasan status pemusnahan disini</Alert>
                    <Form>
                      <FormGroup className="row pl-lg-4">
                        <Label md="3" className="text-capitalize">
                          apakah sudah proses pemusnahan
                        </Label>
                        <Col md="8">
                          <Button onClick={showModalProsesUsulMusnah} >
                            <FontAwesomeIcon icon={faSave}/> Simpan
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </TabPanel>
                  <TabPanel>
                    <Link
                      className="btn btn-primary btn-sm float-left"
                      to={`/authorized/penyusutan/daftar-berkas/usul-musnah/${state.data.id}`}>
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                      />
                    </Link>
                    <PenyusutanUsulMusnahSerahDaftarBerkas data={state.data.daftarBerkas}/>
                  </TabPanel>
                  <TabPanel>
                    <Link
                      className="btn btn-info btn-sm float-left"
                      to={`/authorized/penyusutan/dokumen/usul-musnah/${state.data.id}`}>
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                      />
                    </Link>
                    <PenyusutanUsulMusnahSerahDokumen
                      usul={'musnah'}
                      token={props.token}
                      data={state.data.dokumen}/>
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulMusnahFormMetadata)
