// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'
import Axios from '../helpers/Axios'
import PenyusutanUsulMusnahSerahDokumenRead from './PenyusutanUsulMusnahSerahDokumenRead'

const PenyusutanUsulMusnahSerahDokumen = (props) => {
  PenyusutanUsulMusnahSerahDokumen.propTypes = {
    usul: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
  }

  return (
    <Table bordered responsive>
      <thead className="thead-light">
      <tr>
        <th className="col-md-1">jenis dokumen</th>
        <th className="col-md-2">judul</th>
        <th>nama file</th>
      </tr>
      </thead>
      <tbody className="list">
      {props.data.map((d, i) => {
        return (
          <tr key={i}>
            <td>{d.jenisDokumen.name}</td>
            <td>{d.judul}</td>
            <td>
              <PenyusutanUsulMusnahSerahDokumenRead
                fileName={d.fileName}
                fileType={d.fileType}
                url={`${Axios.defaults.baseURL}/unit-kearsipan/penyusutan/usul-${props.usul}/dokumen/read/${d.id}/${props.token}`}
              />
            </td>
          </tr>
        )
      })}
      </tbody>
    </Table>
  )
}

export default PenyusutanUsulMusnahSerahDokumen
