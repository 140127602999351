import Axios from 'helpers/Axios'
import localStorage from 'localStorage'
import { BASE_URL_API } from 'config'

export default class AuthService {
  constructor (domain) {
    this.domain = BASE_URL_API
    this.fetch = this.fetch.bind(this)
    this.login = this.login.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.baseURL = this.baseURL(this)
  }

  baseURL () {
    return this.domain
  }

  login (username, password) {
    // Get a token
    return this.fetch(`${this.domain}/oauth`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        grant_type: 'password',
        client_id: 'webapp',
        client_secret: 'webappsecret',
        username: username,
        password: password
      })
    })
      .then(res => {
        console.log(res)
        this.setToken(res.access_token)
        return this.fetch(`${this.domain}/api/profile`, {
          method: 'GET'
        })
      })
      .then(res => {
        this.setProfile(res)
        return Promise.resolve(res)
      })
  }

  loggedIn () {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken()
    return !!token // handwaiving here
  }

  isTokenValid () {
    return Axios.get(`${this.domain}/api/profile`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      }
    })
      .then(res => {
        // console.error(res.data);
        return true
      })
      .catch(err => {
        return err
        // console.error(err);
      })
  }

  setProfile (profile) {
    // Saves profile data to localStorage
    localStorage.setItem('profile', JSON.stringify(profile))
  }

  getProfile () {
    // Retrieves the profile data from localStorage
    const profile = localStorage.getItem('profile')
    return profile ? JSON.parse(localStorage.profile) : {}
  }

  refreshProfile () {
    // console.log('withAuth.refreshProfile')
    // get new refresh profile, saves to localStorage
    return Axios.get(`${this.domain}/api/profile`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      }
    })
      .then(res => {
        this.setProfile(res.data)
        return Promise.resolve(res)
      })
      .catch(err => {
        return err
      })
  }

  setToken (idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
  }

  getToken () {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token')
  }

  logout (callback) {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token')
    localStorage.removeItem('profile')
    if (typeof callback === 'function') callback()
  }

  _checkStatus (response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      // console.error(response.statusText);
      // return response.statusText
      const error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }

  fetch (url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (this.loggedIn()) {
      headers.Authorization = 'Bearer ' + this.getToken()
    }

    /*eslint-disable */
    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then(response => response.json())
    /*eslint-disable */
  }
}
