// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Select from 'react-select'
import Toastr from 'toastr'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import Axios from 'helpers/Axios'
import { ORDER_BY_OPTIONS, ORDER_ORIENTATION_OPTIONS, PAGE_SIZE_OPTIONS } from 'config'

const UnitPengolahFilterAndSort = ({ token, onApply }) => {
  UnitPengolahFilterAndSort.propTypes = {
    token: PropTypes.string.isRequired,
    onApply: PropTypes.func
  }

  const [state, setState] = useState({
    dropdownOpen: false,

    search: '',
    orderBy: null,
    orderOrientation: null,
    pageSize: null,
    unitKerja: null,

    orderByOptions: ORDER_BY_OPTIONS,
    orderOrientationOptions: ORDER_ORIENTATION_OPTIONS,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    unitKerjaOptions: []
  })

  useEffect(() => {
    getUnitKerja()
  }, [])

  const getUnitKerja = () => {
    Axios({
      method: 'GET',
      url: '/unit-kerja-list?format=select_options&is-active=true',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data);
        setState((prevProps) => ({
          ...prevProps,
          unitKerjaOptions: res.data
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const handleFilterOrderBy = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      orderBy: obj
    }))
  }

  const onClickOrderByDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      orderBy: null
    }))
  }

  const handleFilterOrderOrientation = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      orderOrientation: obj
    }))
  }

  const onClickOrderOrientationDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      orderOrientation: null
    }))
  }

  const handleFilterPageSize = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      pageSize: obj
    }))
  }

  const onClickPageSizeDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      pageSize: null
    }))
  }

  const handleFilterUnitKerja = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      unitKerja: obj
    }))
  }

  const onClickUnitKerjaDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      unitKerja: null
    }))
  }

  const handleInputChangeSearch = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      search: obj.target.value
    }))
  }

  const onClickSearchDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      search: ''
    }))
  }

  const handleButtonClickOnApply = () => {
    Toastr.info('Sort & Filtering...')
    onApply({
      search: state.search,
      orderBy: state.orderBy,
      orderOrientation: state.orderOrientation,
      pageSize: state.pageSize,
      unitKerja: state.unitKerja
    })
  }

  const toggleFilter = () => {
    setState((prevProps) => ({
      ...prevProps,
      dropdownOpen: !state.dropdownOpen
    }))
  }

  return (
    <>
        {/* {console.log(state)} */}
      <Dropdown isOpen={state.dropdownOpen} toggle={toggleFilter}>
        <DropdownToggle caret>
          <FontAwesomeIcon icon={faFilter}/>
        </DropdownToggle>
        <DropdownMenu>
          <Card className="mb--300 font-weight-300" style={{ width: '800px' }}>
            <CardHeader>
              <h3 className="mb-0">Sort & Filter</h3>
            </CardHeader>
            <CardBody className="bg-gradient-secondary">
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Column</Label>
                    <Select
                      value={state.orderBy}
                      onChange={handleFilterOrderBy}
                      options={state.orderByOptions}
                    />
                    {_.isNull(state.orderBy)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickOrderByDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Ascending/Descending</Label>
                    <Select
                      value={state.orderOrientation}
                      onChange={handleFilterOrderOrientation}
                      options={state.orderOrientationOptions}
                    />
                    {_.isNull(state.orderOrientation)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickOrderOrientationDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Page Size</Label>
                    <Select
                      value={state.pageSize}
                      onChange={handleFilterPageSize}
                      options={state.pageSizeOptions}
                    />
                    {_.isNull(state.pageSize)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickPageSizeDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Unit Kerja</Label>
                    <Select
                      value={state.unitKerja}
                      onChange={handleFilterUnitKerja}
                      options={state.unitKerjaOptions}
                    />
                    {_.isNull(state.unitKerja)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickUnitKerjaDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
                <Col md="8">
                  <FormGroup>
                    <Label>Pencarian</Label>
                    <Input
                      placeholder="Pencarian"
                      type="text"
                      value={state.search}
                      onChange={handleInputChangeSearch}
                    />
                    {_.isEmpty(state.search)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickSearchDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="bg-translucent-secondary">
              <Button
                color="warning"
                onClick={handleButtonClickOnApply}
              >Apply</Button>
            </CardFooter>
          </Card>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default UnitPengolahFilterAndSort
