import React from 'react'
import ReactDOM from 'react-dom'
// react library for routing
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
// bootstrap rtl for rtl support page
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss'
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'toastr/toastr.scss'
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css'
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css'
import 'assets/vendor/select2/dist/css/select2.min.css'
import 'assets/vendor/quill/dist/quill.core.css'
import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0'

import PublicLayout from 'layouts/Public.js'
import AuthorizedLayout from 'layouts/Authorized.js'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/authorized" render={props => <AuthorizedLayout {...props} />}/>
      <Route path="/public" render={props => <PublicLayout {...props} />}/>
      <Redirect from="*" to="/public/login"/>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
)
