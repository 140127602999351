// /* eslint-disable */
import React, { useState } from 'react'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { faDoorClosed, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReadFilePdf from './ReadFilePdf'

const PenyusutanUsulMusnahSerahDokumenRead = (props) => {
  PenyusutanUsulMusnahSerahDokumenRead.propTypes = {
    fileName: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    isModalOpen: false
  })

  const toggleModal = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  if (!_.isEmpty(props.fileName) && !_.isEmpty(props.fileType)) {
    return (
      <>
        <Label style={{ cursor: 'pointer' }} onClick={toggleModal}>{props.fileName}</Label>
        <Modal
          size={'lg'}
          isOpen={state.isModalOpen}
          backdrop='static' centered={true}
          toggle={toggleModal}
        >
          <ModalHeader>{props.fileName}</ModalHeader>
          <ModalBody>
            <ReadFilePdf
              fileName={props.fileName}
              fileType={props.fileType}
              url={props.url}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning">
              <a
                className="text-white"
                href={props.url}
                target='_blank'
                rel='noopener noreferrer'
              ><FontAwesomeIcon icon={faDownload}/> Unduh</a>
            </Button>
            <Button
              color="info"
              onClick={toggleModal}
            ><FontAwesomeIcon icon={faDoorClosed}/> Tutup</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  return (
    <></>
  )
}

export default PenyusutanUsulMusnahSerahDokumenRead
