/* eslint-disable */

export const BASE_URL_API = 'https://arsip-apipauddikdasmen.kemdikbud.go.id'

export const ORDER_ORIENTATION_OPTIONS = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' }
]

export const PAGE_SIZE_OPTIONS = [
  { value: 7, label: '7' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 100, label: '100' },
  { value: 500, label: '500' }
]

export const ORDER_BY_OPTIONS = [
  { value: 'createdAt', label: 'Created At' },
  { value: 'code', label: 'Kode' },
  { value: 'name', label: 'Nama' }
]

