// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Switch from 'react-switch'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import { faBars, faCogs, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'

const JenisDokumenForm = (props) => {
  JenisDokumenForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedOrUpdateData: false,

    id: null,
    name: '',
    description: '',
    isActive: true,

    isModalOpen: false
  })

  useEffect(() => {
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getData = (token, id) => {
    Axios.get(`/unit-kearsipan/penyusutan/jenis-dokumen/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          id: res.data.id,
          name: _.isNull(res.data.name) ? '' : res.data.name,
          description: _.isNull(res.data.description) ? '' : res.data.description,
          isActive: res.data.isActive
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeName = (obj) => {
    const name = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      name
    }))
  }

  const handleChangeDescription = (obj) => {
    const description = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      description
    }))
  }

  const handleChangeIsActive = (isActive) => {
    setState((prevProps) => ({
      ...prevProps,
      isActive
    }))
  }

  const onSubmit = () => {
    // Toastr.info('Menyimpan data...');
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.id) ? 'POST' : 'PATCH',
      url: _.isNull(state.id) ? '/unit-kearsipan/penyusutan/jenis-dokumen' : `/unit-kearsipan/penyusutan/jenis-dokumen/${state.id}`,
      data: {
        name: state.name,
        description: state.description,
        isActive: state.isActive ? 'true' : 'false'
      }
    })
      .then(() => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        Toastr.error('Gagal!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Konflik!')
        }
      })
  }

  const toggleModalDelete = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  const onDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/unit-kearsipan/penyusutan/jenis-dokumen/${state.id}`,
      data: {}
    })
      .then(() => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Data tidak bisa dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isModalOpen: !state.isModalOpen
        }))
      })
  }

  if (state.isSavedOrUpdateData) {
    return <Redirect to={'/authorized/master/jenis-dokumen'}/>
  }

  return (
    <>
      {/* { console.log(state) } */}
      <AuthorizedHeader2 icon={faCogs} data={['master', 'jenis media penyimpanan']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader>
                <Link className="btn btn-primary" to="/authorized/master/jenis-dokumen">
                  <FontAwesomeIcon icon={faBars}/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                {_.isNull(state.id)
                  ? null
                  : (
                    <Alert color="warning">Anda akan mengubah data {state.name}</Alert>
                    )}
                <Form>
                  <FormGroup className="row">
                    <Label md="2">Nama</Label>
                    <Col md="5">
                      <Input
                        value={state.name}
                        onChange={handleChangeName}
                        placeholder="Nama"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Deskripsi</Label>
                    <Col md="5">
                      <Input
                        value={state.description}
                        onChange={handleChangeDescription}
                        placeholder="Deskripsi"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Aktif ?</Label>
                    <Col md="5">
                      <Switch
                        checked={state.isActive}
                        onChange={handleChangeIsActive}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={_.isEmpty(state.name)}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
                {_.isNull(state.id)
                  ? null
                  : (
                    <Button color='danger' onClick={toggleModalDelete}>
                      <FontAwesomeIcon icon={faTrash}/> Hapus
                    </Button>
                    )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalOpen}
        backdrop='static' centered={true}
        toggle={toggleModalDelete}
      >
        <ModalHeader
          toggle={toggleModalDelete}
        >Konfirmasi Hapus Data</ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onDelete}
          >YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModalDelete}
          >Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ValidationAuthorized(JenisDokumenForm)
