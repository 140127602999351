import React from 'react'
import { PropTypes } from 'prop-types'
import DatetimeFormat from './DatetimeFormat'

const DatagridCreatedAtUpdatedAt = (props) => {
  return (
    <>
      <small>
        <em>
          <DatetimeFormat value={props.createdAt} format={'MM/dd/yy HH:mm:ss'}/><br />
          <DatetimeFormat value={props.updatedAt} format={'MM/dd/yy HH:mm:ss'}/>
        </em>
      </small>
    </>
  )
}

DatagridCreatedAtUpdatedAt.propTypes = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string
}

export default DatagridCreatedAtUpdatedAt
