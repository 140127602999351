import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Switch from 'react-switch'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faTable } from '@fortawesome/free-solid-svg-icons'

const TahunForm = (props) => {
  TahunForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isUpdate: false,
    isSavedOrUpdateData: false,
    isValidData: false,
    isMinimalReq: false,

    uid: '',
    code: '',
    name: '',
    description: '',
    isActive: true,

    isModalOpen: false
  })

  useEffect(() => {
    // console.log(props.match);
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getData = (token, id) => {
    Axios.get(`/jra-tahun/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          uid: res.data.id,
          isUpdate: true,
          code: res.data.code,
          name: res.data.name,
          description: res.data.description,
          isActive: res.data.isActive
        }))

        if (!_.isEmpty(res.data.name)) {
          setState((prevProps) => ({
            ...prevProps,
            isMinimalReq: true
          }))
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeCode = (obj) => {
    const code = obj.target.value
    let isMinimalReq = false

    if (!_.isEmpty(state.name) && !_.isEmpty(code)) {
      isMinimalReq = true
    }

    setState((prevProps) => ({
      ...prevProps,
      code,
      isMinimalReq
    }))
  }

  const handleChangeName = (obj) => {
    const name = obj.target.value
    let isMinimalReq = false

    if (!_.isEmpty(state.code) && !_.isEmpty(name)) {
      isMinimalReq = true
    }

    setState((prevProps) => ({
      ...prevProps,
      name,
      isMinimalReq
    }))
  }

  const handleChangeDescription = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      description: obj.target.value
    }))
  }

  const handleChangeIsActive = (isActive) => {
    setState((prevProps) => ({
      ...prevProps,
      isActive
    }))
  }

  const onSubmit = () => {
    // Toastr.info('Menyimpan data...');
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: state.isUpdate ? 'PATCH' : 'POST',
      url: state.isUpdate ? `/jra-tahun/${state.uid}` : '/jra-tahun',
      data: {
        code: state.code,
        name: state.name,
        description: state.description,
        isActive: state.isActive ? 'true' : 'false'
      }
    })
      .then(res => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        Toastr.error('Gagal!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Konflik!')
        }
      })
  }

  const toggleModalDelete = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  const onDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/jra-tahun/${state.uid}`,
      data: {}
    })
      .then(res => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Data tidak bisa dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
  }

  if (state.isSavedOrUpdateData) {
    return <Redirect to={'/authorized/jra/tahun'}/>
  }

  return (
    <>
        {/* { console.log(state) } */}
      <AuthorizedHeader2 icon={faTable} data={['jadwal retensi aktif', 'tahun']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader>
                <Link className="btn btn-primary" to="/authorized/jra/tahun">
                  <i className="fas fa-bars"/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                {state.isUpdate
                  ? <Alert color="warning">Anda akan mengubah data {state.name}</Alert>
                  : null}
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Kode</Label>
                          <Input
                            minLength={3}
                            value={state.code}
                            onChange={handleChangeCode}
                            placeholder="Kode"
                            type="text"
                          />
                          {state.nomorFolderExist
                            ? <FormText color={'danger'}>Maaf, nomor folder yang Anda masukkan sudah terpakai</FormText>
                            : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Nama</Label>
                          <Input
                            value={state.name}
                            onChange={handleChangeName}
                            placeholder="Nama"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Deskripsi</Label>
                          <Input
                            value={state.description}
                            onChange={handleChangeDescription}
                            placeholder="Deskripsi"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <Label>Status Aktif : </Label>
                      <Switch
                        checked={state.isActive}
                        onChange={handleChangeIsActive}
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={!state.isMinimalReq}
                  color='primary'
                  onClick={onSubmit}
                >Simpan</Button>
                {state.isUpdate
                  ? <Button
                  color='danger'
                  disabled={state.jumlahIsiBerkas > 0}
                  onClick={toggleModalDelete}
                >Hapus</Button>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalOpen}
        backdrop='static' centered={true}
        toggle={toggleModalDelete}
      >
        <ModalHeader
          toggle={toggleModalDelete}
        >Konfirmasi Hapus Data</ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onDelete}
          >YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModalDelete}
          >Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ValidationAuthorized(TahunForm)
