import React from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'

const PaginationInfo = (props) => {
  return (
    <>
      <div>
        {props.totalItemsCount} records,
        page : {props.activePage} of {_.ceil(props.totalItemsCount / props.itemsCountPerPage)}
      </div>
    </>
  )
}

PaginationInfo.propTypes = {
  activePage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired
}

export default PaginationInfo
