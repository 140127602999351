import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import Toastr from 'toastr'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'

const Profile = (props) => {
  Profile.propTypes = {
    profile: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    fullName: '',
    email: ''
  })

  useEffect(() => {
    setState((prevProps) => ({
      ...prevProps,
      fullName: props.profile.fullName,
      email: props.profile.email
    }))
  }, [props])

  const handleChangeFullName = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      fullName: obj.target.value
    }))
  }

  const handleChangeEmail = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      email: obj.target.value
    }))
  }

  const onSubmit = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-pengolah/profile/update',
      data: {
        fullName: state.fullName,
        email: state.email
      }
    })
      .then(res => {
        console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
      })
      .catch(() => {
        Toastr.error('Failed!')
      })
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faWrench} data={['konfigurasi', 'profil']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="text-capitalize">profil</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row">
                    <Label md="2">Nama Lengkap</Label>
                    <Col md="5">
                      <Input
                        value={state.fullName}
                        onChange={handleChangeFullName}
                        placeholder="Nama Lengkap"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Email</Label>
                    <Col md="5">
                      <Input
                        value={state.email}
                        onChange={handleChangeEmail}
                        placeholder="Email"
                        type="email"
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={_.isEmpty(state.fullName) || _.isEmpty(state.email)}
                  color='primary'
                  onClick={onSubmit}
                >Simpan</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(Profile)
