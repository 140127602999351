import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import Toastr from 'toastr'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faWrench } from '@fortawesome/free-solid-svg-icons'

const GantiPassword = (props) => {
  GantiPassword.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    passwordValid: null,
    passwordMinLength: 3
  })

  const handleChangeOldPassword = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      oldPassword: obj.target.value
    }))
  }

  const handleChangeNewPassword = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      newPassword: obj.target.value
    }))
  }

  const handleChangeConfirmNewPassword = (obj) => {
    const confirmNewPassword = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      confirmNewPassword
    }))

    if (confirmNewPassword.length >= state.passwordMinLength && _.isEqual(confirmNewPassword, state.newPassword)) {
      setState((prevProps) => ({
        ...prevProps,
        passwordValid: true
      }))
    } else {
      setState((prevProps) => ({
        ...prevProps,
        passwordValid: false
      }))
    }
  }

  const onSubmit = () => {
    // Toastr.info('Change password!');
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/change-password',
      data: {
        oldPassword: state.oldPassword,
        newPassword: state.newPassword,
        confirmNewPassword: state.confirmNewPassword
      }
    })
      .then(() => {
        Toastr.success('Success!')

        setState((prevProps) => ({
          ...prevProps,
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
          passwordValid: null
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Failed!')
      })
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faWrench} data={['konfigurasi', 'ganti password']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="text-capitalize">ganti password</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row">
                    <Label md="2">Password Lama</Label>
                    <Col md="5">
                      <Input
                        value={state.oldPassword}
                        onChange={handleChangeOldPassword}
                        placeholder="Password Lama"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Password Baru</Label>
                    <Col md="5">
                      <Input
                        value={state.newPassword}
                        onChange={handleChangeNewPassword}
                        placeholder="Password Baru"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Konfirmasi Password Baru</Label>
                    <Col md="5">
                      <Input
                        value={state.confirmNewPassword}
                        onChange={handleChangeConfirmNewPassword}
                        placeholder="Konfirmasi Password Baru"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={!state.passwordValid}
                  color='primary'
                  onClick={onSubmit}
                >Ganti Password</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(GantiPassword)
