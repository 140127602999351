// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Select from 'react-select'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faTable } from '@fortawesome/free-solid-svg-icons'

const KlasifikasiForm = (props) => {
  KlasifikasiForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedOrUpdateData: false,

    id: null,
    tahun: null,
    kategori: null,
    subKategori: null,
    code: '',
    name: '',
    retensiAktif: '',
    retensiInaktif: '',
    statusPenanganan: null,

    tahunOptions: [],
    kategoriOptions: [],
    subKategoriOptions: [],
    statusPenangananOptions: [
      { value: 'permanen', label: 'permanen' },
      { value: 'musnah', label: 'musnah' }
    ],

    isModalOpen: false
  })

  useEffect(() => {
    getTahunOptions()
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getTahunOptions = () => {
    Axios.get('/jra-tahun-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          tahunOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getKategoriOptions = (tahun) => {
    Axios.get(`/jra-kategori-list?format=select_options&jra-tahun=${tahun}`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          kategoriOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getSubKategoriOptions = (tahun, kategori) => {
    Axios.get(`/jra-sub-kategori-list?format=select_options&jra-tahun=${tahun}&jra-kategori=${kategori}`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          subKategoriOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (token, id) => {
    Axios.get(`/jra-klasifikasi/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          id: res.data.id,
          code: res.data.code,
          name: res.data.name,
          tahun: _.isNull(res.data.tahun)
            ? null
            : {
                value: res.data.tahun.id,
                label: `[${res.data.tahun.code}] ${res.data.tahun.name}`
              },
          kategori: _.isNull(res.data.kategori)
            ? null
            : {
                value: res.data.kategori.id,
                label: `[${res.data.kategori.code}] ${res.data.kategori.name}`
              },
          subKategori: _.isNull(res.data.subKategori)
            ? null
            : {
                value: res.data.subKategori.id,
                label: `[${res.data.subKategori.code}] ${res.data.subKategori.name}`
              },
          retensiAktif: _.isNull(res.data.retensiAktif) ? '' : _.toString(res.data.retensiAktif),
          retensiInaktif: _.isNull(res.data.retensiInaktif) ? '' : _.toString(res.data.retensiInaktif),
          statusPenanganan: _.isNull(res.data.statusPenanganan)
            ? null
            : {
                value: res.data.statusPenanganan,
                label: res.data.statusPenanganan
              }
        }))
        // custom getKategoriOptions
        if (!_.isNull(res.data.tahun)) {
          getKategoriOptions(res.data.tahun.id)
        }
        // custom getSubKategoriOptions
        if (!_.isNull(res.data.tahun) && !_.isNull(res.data.kategori)) {
          getSubKategoriOptions(res.data.tahun.id, res.data.kategori.id)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeTahun = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      tahun: obj,
      kategori: null,
      kategoriOptions: [],
      subKategori: null,
      subKategoriOptions: []
    }))
    getKategoriOptions(obj.value)
  }

  const handleChangeKategori = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      kategori: obj,
      subKategori: null,
      subKategoriOptions: []
    }))
    getSubKategoriOptions(state.tahun.value, obj.value)
  }

  const handleChangeSubKategori = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      subKategori: obj
    }))
  }

  const handleChangeCode = (obj) => {
    const code = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      code
    }))
  }

  const handleChangeName = (obj) => {
    const name = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      name
    }))
  }

  const handleChangeRetensiAktif = (obj) => {
    const retensiAktif = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      retensiAktif
    }))
  }

  const handleChangeRetensiInaktif = (obj) => {
    const retensiInaktif = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      retensiInaktif
    }))
  }

  const handleChangeStatusPenanganan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      statusPenanganan: obj
    }))
  }

  const onSubmit = () => {
    // Toastr.info('Menyimpan data...');
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.id) ? 'POST' : 'PATCH',
      url: _.isNull(state.id) ? '/jra-klasifikasi' : `/jra-klasifikasi/${state.id}`,
      data: {
        code: state.code,
        name: state.name,
        tahun: _.isObject(state.tahun) ? state.tahun.value : null,
        kategori: _.isObject(state.kategori) ? state.kategori.value : null,
        subKategori: _.isObject(state.subKategori) ? state.subKategori.value : null,
        retensiAktif: state.retensiAktif,
        retensiInaktif: state.retensiInaktif,
        statusPenanganan: _.isObject(state.statusPenanganan) ? state.statusPenanganan.value : null
      }
    })
      .then(() => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        Toastr.error('Gagal!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Konflik!')
        }
      })
  }

  const toggleModalDelete = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  const onDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/jra-klasifikasi/${state.id}`,
      data: {}
    })
      .then(() => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Data tidak bisa dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
  }

  if (state.isSavedOrUpdateData) {
    return <Redirect to={'/authorized/jra/klasifikasi'}/>
  }

  return (
    <>
         {/* { console.log(state) } */}
      <AuthorizedHeader2 icon={faTable} data={['jadwal retensi aktif', 'klasifikasi']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader>
                <Link className="btn btn-primary" to="/authorized/jra/klasifikasi">
                  <i className="fas fa-bars"/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                {_.isNull(state.id)
                  ? null
                  : (
                  <Alert color="warning">Anda akan mengubah data {state.name}</Alert>
                    )}
                <Form>
                  <FormGroup className="row">
                    <Label md="2">Tahun (Periode)</Label>
                    <Col md="5">
                      <Select
                        value={state.tahun}
                        onChange={handleChangeTahun}
                        options={state.tahunOptions}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Kategori</Label>
                    <Col md="5">
                      <Select
                        value={state.kategori}
                        onChange={handleChangeKategori}
                        options={state.kategoriOptions}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Sub Kategori</Label>
                    <Col md="5">
                      <Select
                        value={state.subKategori}
                        onChange={handleChangeSubKategori}
                        options={state.subKategoriOptions}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Kode</Label>
                    <Col md="5">
                      <Input
                        value={state.code}
                        onChange={handleChangeCode}
                        placeholder="Kode"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Nama</Label>
                    <Col md="5">
                      <Input
                        value={state.name}
                        onChange={handleChangeName}
                        placeholder="Nama"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Retensi Aktif</Label>
                    <Col md="5">
                      <Input
                        value={state.retensiAktif}
                        onChange={handleChangeRetensiAktif}
                        placeholder="Retensi Aktif"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Retensi Inaktif</Label>
                    <Col md="5">
                      <Input
                        value={state.retensiInaktif}
                        onChange={handleChangeRetensiInaktif}
                        placeholder="Retensi Inaktif"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Status Penanganan</Label>
                    <Col md="5">
                      <Select
                        value={state.statusPenanganan}
                        onChange={handleChangeStatusPenanganan}
                        options={state.statusPenangananOptions}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={(
                    _.isEmpty(state.code) ||
                    _.isEmpty(state.name) ||
                    _.isNull(state.kategori) ||
                    _.isNull(state.subKategori) ||
                    _.isNull(state.statusPenanganan) ||
                    _.isEmpty(state.retensiAktif) ||
                    _.isEmpty(state.retensiInaktif) ||
                    _.isNull(state.tahun)
                  )}
                  color='primary'
                  onClick={onSubmit}
                >Simpan</Button>
                {_.isNull(state.id)
                  ? null
                  : (
                  <Button color='danger' onClick={toggleModalDelete}>
                    Hapus
                  </Button>
                    )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalOpen}
        backdrop='static' centered={true}
        toggle={toggleModalDelete}
      >
        <ModalHeader
          toggle={toggleModalDelete}
        >Konfirmasi Hapus Data</ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onDelete}
          >YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModalDelete}
          >Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ValidationAuthorized(KlasifikasiForm)
