// /* eslint-disable */
import React from 'react'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import { Alert } from 'reactstrap'

const PenyusutanDokumenFormUploadValidation = (props) => {
  PenyusutanDokumenFormUploadValidation.propTypes = {
    file: PropTypes.object,
    isFileValid: PropTypes.bool
  }
  if (_.isNull(props.file)) {
    return <></>
  }
  return (
    <>
      <Alert color={props.isFileValid ? 'success' : 'danger'}>
        File Name : {props.file.name} <br/>
        File Size : {(props.file.size / 1024 / 1024)} MB <br/>
        {props.isFileValid ? 'File is valid' : 'File not valid'}
      </Alert>
    </>
  )
}

export default PenyusutanDokumenFormUploadValidation
