// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Select from 'react-select'
import Toastr from 'toastr'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMinusSquare, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import Axios from 'helpers/Axios'
import { ORDER_ORIENTATION_OPTIONS, PAGE_SIZE_OPTIONS } from 'config'

const TransaksiPeminjamanFilterAndSort = ({ token, onApply }) => {
  TransaksiPeminjamanFilterAndSort.propTypes = {
    token: PropTypes.string.isRequired,
    onApply: PropTypes.func
  }

  const [state, setState] = useState({
    dropdownOpen: false,
    berkas: null,
    berkasOptions: [],
    search: '',
    orderBy: null,
    orderOrientation: null,
    pageSize: null,
    orderByOptions: [
      { value: 'createdAt', label: 'Tanggal Penciptaan' },
      { value: 'nomorDokumen', label: 'Nomor Dokumen' },
      { value: 'judul', label: 'Judul' },
      { value: 'tanggalDokumen', label: 'Tanggal Dokumen' }
    ],
    orderOrientationOptions: ORDER_ORIENTATION_OPTIONS,
    pageSizeOptions: PAGE_SIZE_OPTIONS

  })

  useEffect(() => {
    getBerkas()
  }, [])

  const getBerkas = () => {
    Axios.get('/unit-kearsipan/daftar-berkas-list?is-active=true&format=select_options&sedang-dipinjam=true', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          berkasOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleFilterOrderBy = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      orderBy: obj
    }))
  }

  const onClickOrderByDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      orderBy: null
    }))
  }

  const handleFilterOrderOrientation = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      orderOrientation: obj
    }))
  }

  const onClickOrderOrientationDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      orderOrientation: null
    }))
  }

  const handleFilterPageSize = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      pageSize: obj
    }))
  }

  const onClickPageSizeDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      pageSize: null
    }))
  }

  const handleFilterBerkas = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      berkas: obj
    }))
  }

  const onClickBerkasDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      berkas: null
    }))
  }

  const handleInputChangeSearch = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      search: obj.target.value
    }))
  }

  const onClickSearchDefault = () => {
    setState((prevProps) => ({
      ...prevProps,
      search: ''
    }))
  }

  const handleButtonClickOnApply = () => {
    Toastr.info('Sort & Filtering...')
    onApply({
      search: state.search,
      orderBy: state.orderBy,
      orderOrientation: state.orderOrientation,
      pageSize: state.pageSize,
      berkas: state.berkas
    })
  }

  const toggleFilter = () => {
    setState((prevProps) => ({
      ...prevProps,
      dropdownOpen: !state.dropdownOpen
    }))
  }

  return (
    <>
       {/* {console.log(state)} */}
      <Dropdown isOpen={state.dropdownOpen} toggle={toggleFilter}>
        <DropdownToggle caret>
          <FontAwesomeIcon icon={faFilter}/>
        </DropdownToggle>
        <DropdownMenu>
          <Card className="mb--300 font-weight-300" style={{ width: '800px' }}>
            <CardHeader>
              <h3 className="mb-0">Sort & Filter</h3>
            </CardHeader>
            <CardBody className="bg-gradient-secondary">
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Column</Label>
                    <Select
                      value={state.orderBy}
                      onChange={handleFilterOrderBy}
                      options={state.orderByOptions}
                    />
                    {_.isNull(state.orderBy)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickOrderByDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Ascending/Descending</Label>
                    <Select
                      value={state.orderOrientation}
                      onChange={handleFilterOrderOrientation}
                      options={state.orderOrientationOptions}
                    />
                    {_.isNull(state.orderOrientation)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickOrderOrientationDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Page Size</Label>
                    <Select
                      value={state.pageSize}
                      onChange={handleFilterPageSize}
                      options={state.pageSizeOptions}
                    />
                    {_.isNull(state.pageSize)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickPageSizeDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Berkas</Label>
                    <Select
                      value={state.berkas}
                      onChange={handleFilterBerkas}
                      options={state.berkasOptions}
                    />
                    {_.isNull(state.berkas)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickBerkasDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Pencarian</Label>
                    <Input
                      placeholder="Pencarian"
                      type="text"
                      value={state.search}
                      onChange={handleInputChangeSearch}
                    />
                    {_.isEmpty(state.search)
                      ? null
                      : <Link to={'#'}>
                        <FontAwesomeIcon onClick={onClickSearchDefault} icon={faMinusSquare}/>
                      </Link>}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="bg-translucent-secondary">
              <Button
                color="warning"
                onClick={handleButtonClickOnApply}
              ><FontAwesomeIcon icon={faCheckDouble}/> Terapkan</Button>
            </CardFooter>
          </Card>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default TransaksiPeminjamanFilterAndSort
