// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faEye, faDoorClosed, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import Axios from 'helpers/Axios'
import PaginationInfo from 'components/Utils/PaginationInfo'
import DatagridCreatedAtUpdatedAt from 'components/Utils/DatagridCreatedAtUpdatedAt'
import DaftarBerkasFilterAndSort from './DaftarBerkasFilterAndSort'
import ArsipUkMetadata from 'components/ArsipUkMetadata'
import ArsipUkIsiBerkas from 'components/ArsipUkIsiBerkas'
import DatetimeFormatV3 from 'components/Utils/DatetimeFormatV3'
import IconBoolean from 'components/Utils/IconBoolean'
import ArsipUkAsalBerkas from 'components/ArsipUkAsalBerkas'
import LoadingContent from 'components/Utils/LoadingContent'

const ArsipUKDaftarBerkasDataGrid = (props) => {
  ArsipUKDaftarBerkasDataGrid.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
    pageSize: 7,
    data: null,
    search: '',
    filter: {},
    uidSelected: null
  })

  useEffect(() => {
    // console.log('useEffect first load')
    getData(props.token, 1, null)
  }, [props])

  const getData = (token, page, filter) => {
    // console.log(filter)
    let url = `/unit-kearsipan/daftar-berkas?page=${page}`
    if (_.isObject(filter)) {
      // unitPengolah
      if (_.isObject(filter.unitPengolah)) {
        url += `&unit-pengolah=${filter.unitPengolah.value}`
      }
      // rak
      if (_.isObject(filter.rak)) {
        url += `&uk-rak=${filter.rak.value}`
      }
      // search
      if (_.has(filter, 'search')) {
        if (!_.isEmpty(filter.search)) {
          url += `&search=${filter.search}`
        }
      }
      // orderBy
      if (_.has(filter, 'orderBy') && _.has(filter, 'orderOrientation')) {
        if (_.isObject(filter.orderBy) && _.isObject(filter.orderOrientation)) {
          url += `&sort={"${filter.orderBy.value}":"${filter.orderOrientation.value}"}`
        }
      }
      // pageSize
      if (_.has(filter, 'pageSize')) {
        if (_.isObject(filter.pageSize)) {
          setState((prevProps) => ({
            ...prevProps,
            itemsCountPerPage: filter.pageSize.value
          }))
          url += `&page-size=${filter.pageSize.value}`
        }
      } else {
        url += `&page-size=${state.pageSize}`
      }
    }
    // console.log(url)
    Axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          data: res.data._embedded.unit_kearsipan_daftar_berkas,
          activePage: res.data.page,
          itemsCountPerPage: res.data.page_size,
          totalItemsCount: res.data.total_items
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const handlePageChange = (pageNumber) => {
    setState((prevProps) => ({
      ...prevProps,
      activePage: pageNumber
    }))
    getData(props.token, pageNumber, state.filter)
  }

  const handleFilterApply = (filterAndSort) => {
    const filter = state.filter
    Object.assign(filter, filterAndSort)
    setState((prevProps) => ({
      ...prevProps,
      filter: filter
    }))
    getData(props.token, 1, filter)
  }

  const onModalShow = (e) => {
    const uidSelected = e.currentTarget.getAttribute('data-value-uid')
    // console.log(uidSelected)
    setState((prevProps) => ({
      ...prevProps,
      uidSelected
    }))
  }

  const handleApplyShowDetail = () => {
    setState((prevProps) => ({
      ...prevProps,
      uidSelected: null
    }))
  }

  if (_.isNull(state.data)) {
    return <LoadingContent />
  }

  return (
    <>
      {/* {console.log(state)} */}

      {_.isNull(state.uidSelected)
        ? null
        : <ShowDetail
          uid={state.uidSelected}
          token={props.token}
          onApplyShowDetail={handleApplyShowDetail}
        />
      }

      <AuthorizedHeader2 icon={faArchive} data={['arsip unit kearsipan', 'daftar berkas']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <DaftarBerkasFilterAndSort token={props.token} onApply={handleFilterApply}/>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th width="1%">
                    Op.
                  </th>
                  <th scope="col">
                    Unit Kerja /<br/>Unit Pengolah
                  </th>
                  <th scope="col">
                    Rak
                  </th>
                  <th scope="col">
                    Nomor<br/>Folder (UK)
                  </th>
                  <th scope="col">
                    Retensi <br/>Inaktif
                  </th>
                  <th scope="col">
                    sedang<br/>dipinjam
                  </th>
                  <th scope="col">
                    Judul
                  </th>
                  <th scope="col">
                    Kode<br/>Klasifikasi
                  </th>
                  <th scope="col">
                    <small>Created At<br/>Updated At</small>
                  </th>
                </tr>
                </thead>
                <tbody className="list">
                {state.data.length === 0
                  ? <tr>
                    <td colSpan={5}><code>...</code></td>
                  </tr>
                  : null}
                {state.data.map((d, i) => {
                  return (
                    <tr
                      key={i}
                      className={_.isBoolean(d.isRetensiAktifExpired) ? isRetensiAktifExpired(d.isRetensiAktifExpired) : 'text-default'}
                    >
                      <td>
                        <FontAwesomeIcon
                          style={{ cursor: 'pointer' }}
                          icon={faEye}
                          data-value-i={i}
                          data-value-uid={d.id}
                          onClick={onModalShow}
                        />
                      </td>
                      <td>{d.unitKerja.name} /<br/>{d.unitPengolah.name}</td>
                      <td>{_.isNull(d.rak) ? null : d.rak.name}</td>
                      <td>{d.nomorFolderUk}</td>
                      <td>
                        <DatetimeFormatV3 value={d.retensiInaktifDueTime} format={'dd/MMM/yyyy'} />
                      </td>
                      <td>{d.apakahSedangDipinjam ? <IconBoolean value={d.apakahSedangDipinjam}/> : '-'}</td>
                      <td>{d.judul}</td>
                      <td>{_.isNull(d.jraKlasifikasi) ? null : d.jraKlasifikasi.code}</td>
                      <td><DatagridCreatedAtUpdatedAt createdAt={d.createdAt} updatedAt={d.updatedAt}/></td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    innerClass="pagination justify-content-end mb-0"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="⟨"
                    nextPageText="⟩"
                    firstPageText="«"
                    lastPageText="»"
                    activePage={state.activePage}
                    itemsCountPerPage={state.itemsCountPerPage}
                    totalItemsCount={state.totalItemsCount}
                    pageRangeDisplayed={state.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                  <PaginationInfo
                    activePage={state.activePage}
                    totalItemsCount={state.totalItemsCount}
                    itemsCountPerPage={state.itemsCountPerPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(ArsipUKDaftarBerkasDataGrid)

const isRetensiAktifExpired = (props) => {
  return props ? 'text-danger' : 'text-default'
}

const ShowDetail = ({ uid, token, onApplyShowDetail }) => {
  ShowDetail.propTypes = {
    uid: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    onApplyShowDetail: PropTypes.func
  }

  const [state, setState] = useState({
    isOpen: true,
    data: null
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    Axios.get(`/unit-kearsipan/daftar-berkas/${uid}?isi-berkas=true&asal-berkas=true`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const toggleView = () => {
    setState((prevProps) => ({
      ...prevProps,
      isOpen: !state.isOpen
    }))
    onApplyShowDetail()
  }

  if (_.isNull(state.data)) {
    return <></>
  }

  return (
    <>
       {/* {console.log(state)} */}
      <Modal size={'lg'} isOpen={state.isOpen}>
        <ModalHeader>Daftar Berkas</ModalHeader>
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>Metadata</Tab>
              <Tab>Isi Berkas</Tab>
              <Tab>Asal Berkas (Usul Pindah)</Tab>
            </TabList>
            <TabPanel>
              <Table>
                <tbody>
                <tr>
                  <td className="text-right text-capitalize col-md-3">rak :</td>
                  <td className="text-left">{_.isNull(state.data.rak) ? null : state.data.rak.name}</td>
                </tr>
                <tr>
                  <td className="text-right text-capitalize">nomor folder (UK) :</td>
                  <td className="text-left">{state.data.nomorFolderUk}</td>
                </tr>
                </tbody>
              </Table>
              <ArsipUkMetadata data={state.data} />
            </TabPanel>
            <TabPanel>
              <ArsipUkIsiBerkas data={state.data.isiBerkas} />
            </TabPanel>
            <TabPanel>
              <ArsipUkAsalBerkas data={state.data.asalBerkas} />
            </TabPanel>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Link
            to={`/authorized/update/arsip-uk/daftar-berkas/${state.data.id}`}>
            <Button
              color="primary"><FontAwesomeIcon icon={faPencilAlt}/> Edit</Button>
          </Link>
          <Button
            onClick={toggleView}
            color="warning"><FontAwesomeIcon icon={faDoorClosed}/> Tutup</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
