// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'
import _ from 'lodash'
import DatetimeFormatV2 from './Utils/DatetimeFormatV2'

const ArsipUkMetadata = (props) => {
  ArsipUkMetadata.propTypes = {
    data: PropTypes.object.isRequired
  }

  return (
    <>
      {/* {console.log(props.data)} */}
      <Table>
        <tbody>
        <tr>
          <td className="text-right text-capitalize col-md-3">nomor folder (UP) :</td>
          <td className="text-left">{props.data.nomorFolder}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">unit kerja <br/>unit pengolah :</td>
          <td className="text-left">
            {_.isNull(props.data.unitKerja) ? null : props.data.unitKerja.name} <br/>
            {_.isNull(props.data.unitPengolah) ? null : props.data.unitPengolah.name}
          </td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">judul :</td>
          <td className="text-left">{props.data.judul}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">klasifikasi :</td>
          <td className="text-left">
            {_.isNull(props.data.jraKlasifikasi)
              ? null
              : <>
                Tahun : {props.data.jraKlasifikasi.tahun.name}
                <br/>
                Kategori : {props.data.jraKlasifikasi.kategori.name}
                <br/>
                Sub Kategori : {props.data.jraKlasifikasi.subKategori.name}
                <br/>
                Klasifikasi : <strong>{props.data.jraKlasifikasi.code}</strong> - {props.data.jraKlasifikasi.name}
              </>
            }
          </td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">
            kurun waktu :
          </td>
          <td className="text-left">
            mulai : <DatetimeFormatV2 value={props.data.kurunWaktuMulai}/> <br/>
            selesai : <DatetimeFormatV2 value={props.data.kurunWaktuSelesai}/>
          </td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">
            retensi :
          </td>
          <td className="text-left">
            aktif : <DatetimeFormatV2 value={props.data.retensiAktifDueTime}/> ({props.data.retensiAktif} tahun) <br/>
            inaktif : <DatetimeFormatV2 value={props.data.retensiInaktifDueTime}/> ({props.data.retensiInaktif} tahun)
          </td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">satuan berkas :</td>
          <td className="text-left">{props.data.jumlahBerkasSatuan}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">jumlah isi berkas :</td>
          <td className="text-left">{props.data.jumlahIsiBerkas}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">klasifikasi keamanan :</td>
          <td
            className="text-left">{_.isNull(props.data.klasifikasiKeamanan) ? null : props.data.klasifikasiKeamanan.name}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">tingkat perkembangan :</td>
          <td
            className="text-left">{_.isNull(props.data.tingkatPerkembangan) ? null : props.data.tingkatPerkembangan.name}</td>
        </tr>
        <tr>
          <td className="text-right text-capitalize">vital / non vital :</td>
          <td className="text-left">{props.data.vitalNonVital}</td>
        </tr>
        </tbody>
      </Table>
    </>
  )
}
export default ArsipUkMetadata
