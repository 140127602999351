// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UsulSerahMetadata from './UsulSerahMetadata'
import PenyusutanUsulMusnahSerahDaftarBerkas from './PenyusutanUsulMusnahSerahDaftarBerkas'
import PenyusutanUsulMusnahSerahDokumen from './PenyusutanUsulMusnahSerahDokumen'

const UsulSerahDetail = (props) => {
  UsulSerahDetail.propTypes = {
    token: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  }

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Metadata</Tab>
          <Tab>Daftar Berkas</Tab>
          <Tab>Dokumen</Tab>
        </TabList>
        <TabPanel>
          <Link
            className="btn btn-default btn-sm float-left "
            to={`/authorized/penyusutan/metadata/usul-serah/${props.data.id}`}>
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faPencilAlt}
            />
          </Link>
          <UsulSerahMetadata data={props.data}/>
        </TabPanel>
        <TabPanel>
          <PenyusutanUsulMusnahSerahDaftarBerkas data={props.data.daftarBerkas}/>
        </TabPanel>
        <TabPanel>
          <PenyusutanUsulMusnahSerahDokumen
            usul={'serah'}
            token={props.token}
            data={props.data.dokumen}/>
        </TabPanel>
      </Tabs>

    </>
  )
}

export default UsulSerahDetail
