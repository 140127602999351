import React from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { format, parseISO, isDate } from 'date-fns'

const DatetimeFormatV3 = (props) => {
  if (!_.isEmpty(props.value)) {
    const resultDatetime = parseISO(props.value)
    // console.log(resultDatetime)
    if (_.isDate(resultDatetime)) {
      if (!_.isEqual(resultDatetime.toString(), 'Invalid Date')) {
        return (
          <>
            {format(resultDatetime, props.format)}
          </>
        )
      }
    }
  }

  if (isDate(props.value)) {
    // console.log(props.value.toString())
    if (!_.isEqual(props.value.toString(), 'Invalid Date')) {
      return (
        <>
          {format(props.value, props.format)}
        </>
      )
    }
  }

  return (
    <></>
  )
}

DatetimeFormatV3.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.date
  ]),
  format: PropTypes.string
}

export default DatetimeFormatV3
