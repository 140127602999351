// /* eslint-disable */
import React, { useState } from 'react'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { faDoorClosed, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'helpers/Axios'
import ReadFilePdf from './ReadFilePdf'

const UsulPindahSuratUsulan = (props) => {
  UsulPindahSuratUsulan.propTypes = {
    token: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isModalOpen: false
  })

  const toggleModal = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  if (!_.isEmpty(props.data.suratUsulanFileName) && !_.isEmpty(props.data.suratUsulanFileType)) {
    return (
      <>
        <Label style={{ cursor: 'pointer' }} onClick={toggleModal}>{props.data.suratUsulanFileName}</Label>
        <Modal
          size={'lg'}
          isOpen={state.isModalOpen}
          backdrop='static' centered={true}
          toggle={toggleModal}
        >
          <ModalHeader>Surat Usulan</ModalHeader>
          <ModalBody>
            <ReadFilePdf
              fileName={props.data.suratUsulanFileName}
              fileType={props.data.suratUsulanFileType}
              url={`${Axios.defaults.baseURL}/unit-pengolah/read/file/usul-pindah/surat-usulan/${props.data.id}/${props.token}`}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning">
              <a
                className="text-white"
                href={`${Axios.defaults.baseURL}/unit-pengolah/read/file/usul-pindah/surat-usulan/${props.data.id}/${props.token}`}
                target='_blank'
                rel='noopener noreferrer'
              ><FontAwesomeIcon icon={faDownload}/> Unduh</a>
            </Button>
            <Button
              color="info"
              onClick={toggleModal}
            ><FontAwesomeIcon icon={faDoorClosed}/> Tutup</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  return (
    <></>
  )
}

export default UsulPindahSuratUsulan
