import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import routesPublic from 'routesPublic'

class Public extends React.Component {
  componentDidMount () {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    document.body.classList.add('bg-gradient-info')
  }

  componentWillUnmount () {
    // document.body.classList.remove('bg-default')
  }

  componentDidUpdate (e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
    }
  }

  getRoutes = routesPublic => {
    return routesPublic.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/public') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  };

  render () {
    return (
      <>
        <div
          // ref="mainContent"
          className="main-content">
          <Switch>
            {this.getRoutes(routesPublic)}
            <Redirect from="*" to="/public/index"/>
          </Switch>
        </div>
      </>
    )
  }
}

export default Public
