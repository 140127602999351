// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { faHourglassHalf, faPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatetimeFormatV2 from './DatetimeFormatV2'

const LabelApakahSudahDikirimKeUnitKearsipan = (props) => {
  LabelApakahSudahDikirimKeUnitKearsipan.propTypes = {
    apakahSudahDikirimKeUnitKearsipan: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    tanggalDikirimKeUnitKearsipan: PropTypes.string.isRequired
  }

  if (_.isString(props.apakahSudahDikirimKeUnitKearsipan)) {
    return <FontAwesomeIcon color="red" icon={faHourglassHalf}/>
  }

  if (props.apakahSudahDikirimKeUnitKearsipan) {
    return (
      <>
        <FontAwesomeIcon color="green" icon={faPlane}/> <em><DatetimeFormatV2 value={props.tanggalDikirimKeUnitKearsipan}/></em>
      </>
    )
  } else {
    return (
      <FontAwesomeIcon color="red" icon={faHourglassHalf}/>
    )
  }
}

export default LabelApakahSudahDikirimKeUnitKearsipan
