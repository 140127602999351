// /* eslint-disable */
import React from 'react'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import PDFJSBackend from 'backends/pdfjs'

const ReadFilePdf = (props) => {
  ReadFilePdf.propTypes = {
    fileName: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }

  if (_.isEqual(props.fileType, 'application/pdf')) {
    return (
      <>
        <div style={{ overflow: 'scroll', height: 400 }}>
          <PDFViewer backend={PDFJSBackend} src={props.url}/>
        </div>
      </>
    )
  }

  return (
    <>-</>
  )
}

export default ReadFilePdf
