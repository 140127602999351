// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Button, Card, CardBody, CardHeader, Col, Container, FormText, Input, Row, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader from 'components/Headers/AuthorizedHeader'
import PenyusutanUsulMusnahSerahDokumen from 'components/PenyusutanUsulMusnahSerahDokumen'
import UsulMusnahMetadata from 'components/UsulMusnahMetadata'
import LoadingContent from 'components/Utils/LoadingContent'

const UsulMusnahFormDaftarBerkas = (props) => {
  UsulMusnahFormDaftarBerkas.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    data: null,
    daftarBerkasOptions: null,
    daftarBerkasCheckedState: null
  })

  useEffect(() => {
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
    getDaftarBerkas()
    getDaftarBerkasCheckedState(props.match.params.uid)
  }, [props])

  const getDaftarBerkas = () => {
    Axios.get('/unit-kearsipan/daftar-berkas-list?format=select_options&apakah-sedang-dipinjam=false&apakah-penyusutan-usul-serah=null&apakah-penyusutan-usul-musnah=null', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          daftarBerkasOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getDaftarBerkasCheckedState = (uid) => {
    Axios.get(`/unit-kearsipan/daftar-berkas-checked-state-list?usul-musnah=${uid}`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          daftarBerkasCheckedState: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (id) => {
    Axios.get(`/unit-kearsipan/penyusutan/usul-musnah/${id}?include-dokumen=true&include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleOnChange = (position) => {
    const tempState = []
    state.daftarBerkasCheckedState.map((d, i) => {
      tempState.push(i === position ? !d : d)
      return tempState
    })
    setState((prevProps) => ({
      ...prevProps,
      daftarBerkasCheckedState: tempState
    }))
  }

  const onSubmit = () => {
    let daftarBerkasString = ''
    state.daftarBerkasOptions.map((d, i) => {
      if (state.daftarBerkasCheckedState[i]) {
        daftarBerkasString += `${(d.value)},`
      }
      return daftarBerkasString
    })
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/penyusutan/usul-musnah/daftar-berkas/update',
      data: {
        usulMusnah: state.data.id,
        daftarBerkas: `[${daftarBerkasString}]`
      }
    })
      .then(() => {
        Toastr.info('Sukses...')
        getData(props.match.params.uid)
        getDaftarBerkasCheckedState(props.match.params.uid)
      })
      .catch(() => {
        Toastr.error('Gagal!')
      })
  }

  if (
    _.isNull(state.data) ||
    _.isNull(state.daftarBerkasOptions) ||
    _.isNull(state.daftarBerkasCheckedState)) {
    return <LoadingContent/>
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader name="Penyusutan" parentName="Usul Musnah"/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-black-50 text-underline text-capitalize">daftar berkas</h1>
              </CardHeader>
              <CardBody>
                <Tabs defaultIndex={1}>
                  <TabList>
                    <Tab>Metadata</Tab>
                    <Tab>Daftar Berkas</Tab>
                    <Tab>Dokumen</Tab>
                  </TabList>
                  <TabPanel>
                    <Link
                      className="btn btn-default btn-sm float-left"
                      to={`/authorized/penyusutan/metadata/usul-musnah/${state.data.id}`}>
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                      />
                    </Link>
                    <UsulMusnahMetadata data={state.data}/>
                  </TabPanel>
                  <TabPanel>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="9">
                          <FormText>* Daftar Berkas yang memenuhi syarat proses usul musnah</FormText>
                          <Table bordered responsive>
                            <thead className="thead-light">
                            <tr>
                              <th>_</th>
                              <th>Nama Berkas</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {state.daftarBerkasOptions.map((d, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">
                                    <Input
                                      type="checkbox"
                                      checked={state.daftarBerkasCheckedState[i]}
                                      onChange={() => handleOnChange(i)}
                                    />
                                  </td>
                                  <td>{d.label}</td>
                                </tr>
                              )
                            })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <p/>
                      <Button
                        disabled={state.data.apakahSudahMusnah}
                        color='primary'
                        onClick={onSubmit}
                      ><FontAwesomeIcon icon={faSave}/> simpan</Button>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <Link
                      className="btn btn-info btn-sm float-left"
                      to={`/authorized/penyusutan/dokumen/usul-musnah/${state.data.id}`}>
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                      />
                    </Link>
                    <PenyusutanUsulMusnahSerahDokumen
                      usul={'musnah'}
                      token={props.token}
                      data={state.data.dokumen}/>
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulMusnahFormDaftarBerkas)
