// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Select from 'react-select'
import Switch from 'react-switch'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import isEmail from 'is-email'
import isAlphanumeric from 'is-alphanumeric'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

const UserUnitPengolahForm = (props) => {
  UserUnitPengolahForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedOrUpdateData: false,

    id: null,
    username: '',
    password: '',
    email: '',
    fullName: '',
    description: '',
    isActive: true,
    unitPengolah: null,

    unitPengolahOptions: [],
    isModalOpen: false
  })

  useEffect(() => {
    getUnitPengolah(props.token)
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getUnitPengolah = (token) => {
    Axios.get('/unit-pengolah-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          unitPengolahOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (token, id) => {
    Axios.get(`/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          id: res.data.id,
          username: res.data.username,
          fullName: _.isNull(res.data.fullName) ? '' : res.data.fullName,
          email: _.isNull(res.data.email) ? '' : res.data.email,
          description: _.isNull(res.data.description) ? '' : res.data.description,
          isActive: res.data.isActive,
          unitPengolah: _.isNull(res.data.unitPengolah)
            ? null
            : {
                code: res.data.unitPengolah.code,
                value: res.data.unitPengolah.id,
                label: `${res.data.unitKerja.name} / ${res.data.unitPengolah.name}`
              }
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeUsername = (obj) => {
    const username = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      username
    }))
  }

  const handleChangePassword = (obj) => {
    const password = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      password
    }))
  }

  const handleChangeEmail = (obj) => {
    const email = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      email
    }))
  }

  const handleChangeFullName = (obj) => {
    const fullName = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      fullName
    }))
  }

  const handleChangeUnitPengolah = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      unitPengolah: obj
    }))
  }

  const handleChangeDescription = (obj) => {
    const description = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      description
    }))
  }

  const handleChangeIsActive = (isActive) => {
    setState((prevProps) => ({
      ...prevProps,
      isActive
    }))
  }

  const onSubmit = () => {
    // Toastr.info('Menyimpan data...');
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.id) ? 'POST' : 'PATCH',
      url: _.isNull(state.id) ? '/users' : `/users/${state.id}`,
      data: {
        username: state.username,
        password: state.password,
        fullName: state.fullName,
        description: state.description,
        email: state.email,
        isUnitPengolah: 'true',
        unitPengolah: _.isNull(state.unitPengolah) ? '' : state.unitPengolah.value,
        isActive: state.isActive ? 'true' : 'false'
      }
    })
      .then(res => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        Toastr.error('Gagal!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Konflik!')
        }
      })
  }

  const toggleModalDelete = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  const onDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/users/${state.id}`,
      data: {}
    })
      .then(res => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Data tidak bisa dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
  }

  if (state.isSavedOrUpdateData) {
    return <Redirect to={'/authorized/user/unit-pengolah'}/>
  }

  return (
    <>
         {/* { console.log(state) } */}
      <AuthorizedHeader2 icon={faUsers} data={['pengguna', 'unit pengolah']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader>
                <Link className="btn btn-primary" to="/authorized/user/unit-pengolah">
                  <i className="fas fa-bars"/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                {_.isNull(state.id)
                  ? null
                  : (
                    <Alert color="warning">Anda akan mengubah data {state.username}</Alert>
                    )}
                <Form>
                  <FormGroup className="row">
                    <Label md="2">Username</Label>
                    <Col md="6">
                      <Input
                        disabled={!_.isNull(state.id)}
                        value={state.username}
                        onChange={handleChangeUsername}
                        placeholder="Username"
                        type="text"
                      />
                      <FormText>alphanumerik</FormText>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Password</Label>
                    <Col md="6">
                      <Input
                        value={state.password}
                        onChange={handleChangePassword}
                        placeholder="Password"
                        type="password"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Full Name</Label>
                    <Col md="6">
                      <Input
                        value={state.fullName}
                        onChange={handleChangeFullName}
                        placeholder="Full Name"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Email</Label>
                    <Col md="6">
                      <Input
                        value={state.email}
                        onChange={handleChangeEmail}
                        placeholder="Email"
                        type="email"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Unit Pengolah</Label>
                    <Col md="6">
                      <Select
                        value={state.unitPengolah}
                        onChange={handleChangeUnitPengolah}
                        options={state.unitPengolahOptions}
                      />
                      <FormText>Unit Kerja/Unit Pengolah</FormText>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Deskripsi</Label>
                    <Col md="6">
                      <Input
                        value={state.description}
                        onChange={handleChangeDescription}
                        placeholder="Deskripsi"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label md="2">Aktif ?</Label>
                    <Col md="6">
                      <Switch
                        checked={state.isActive}
                        onChange={handleChangeIsActive}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={(
                    !isAlphanumeric(state.username) ||
                    _.isEmpty(state.username) ||
                    _.isEmpty(state.fullName) ||
                    !isEmail(state.email) ||
                    _.isNull(state.unitPengolah)
                  )}
                  color='primary'
                  onClick={onSubmit}
                >Simpan</Button>
                {_.isNull(state.id)
                  ? null
                  : (
                    <Button color='danger' onClick={toggleModalDelete}>
                      Hapus
                    </Button>
                    )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalOpen}
        backdrop='static' centered={true}
        toggle={toggleModalDelete}
      >
        <ModalHeader
          toggle={toggleModalDelete}
        >Konfirmasi Hapus Data</ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onDelete}
          >YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModalDelete}
          >Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ValidationAuthorized(UserUnitPengolahForm)
