import React from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

const IconBoolean = (props) => {
  IconBoolean.propTypes = {
    value: PropTypes.bool.isRequired
  }

  let result = null
  if (_.isBoolean(props.value)) {
    result = <FontAwesomeIcon color="red" icon={faMinusCircle}/>
    if (props.value) {
      result = <FontAwesomeIcon color="green" icon={faCheck}/>
    }
  }
  return (
    <>{ result }</>
  )
}

export default IconBoolean
