/* eslint-disable */
import React from 'react'
import {PropTypes} from 'prop-types'
import {Table} from 'reactstrap'

const PenyusutanUsulMusnahSerahDaftarBerkas = (props) => {
  PenyusutanUsulMusnahSerahDaftarBerkas.propTypes = {
    data: PropTypes.array.isRequired
  }

  return (
    <Table bordered responsive>
      <thead className="thead-light">
      <tr>
        <th className="col-md-1">nomor <br/>folder</th>
        <th className="col-md-3">nama berkas</th>
      </tr>
      </thead>
      <tbody className="list">
      {props.data.map((d, i) => {
        return (
          <tr key={i}>
            <td>{d.nomorFolderUk}</td>
            <td>{d.judul}</td>
          </tr>
        )
      })}
      </tbody>
    </Table>
  )
}

export default PenyusutanUsulMusnahSerahDaftarBerkas
