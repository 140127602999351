import Login from 'views/pages/Public/Login'

const routesPublic = [
  {
    path: '/index',
    component: Login,
    layout: '/public'
  }
]

export default routesPublic
