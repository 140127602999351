// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { Button } from 'reactstrap'
import DatetimeFormatV2 from './DatetimeFormatV2'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LabelApakahSudahLulusVerifikasi = (props) => {
  LabelApakahSudahLulusVerifikasi.propTypes = {
    apakahSudahLulusVerifikasi: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    tanggalVerifikasi: PropTypes.string.isRequired,
    alasanVerifikasiDitolak: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ])
  }

  if (_.isString(props.apakahSudahLulusVerifikasi)) {
    return <FontAwesomeIcon color="red" icon={faHourglassHalf}/>
  }

  if (props.apakahSudahLulusVerifikasi) {
    return (
      <>
        <Button className="btn-sm text-uppercase" color="success">Disetujui</Button> <br />
        <em><DatetimeFormatV2 value={props.tanggalVerifikasi}/></em>
      </>
    )
  } else {
    return (
      <>
        <Button className="btn-sm text-uppercase" color="danger">ditolak</Button><em><DatetimeFormatV2 value={props.tanggalVerifikasi}/></em> <br />
        Alasan : <em>{props.alasanVerifikasiDitolak}</em>
      </>
    )
  }
}

export default LabelApakahSudahLulusVerifikasi
