// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap'
import Toastr from 'toastr'
import { PropTypes } from 'prop-types'
import { faSave, faWrench } from '@fortawesome/free-solid-svg-icons'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import _ from 'lodash'

const Configuration = (props) => {
  Configuration.propTypes = {
    profile: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    jraTahunActive: null,
    jraTahunActiveOptions: []
  })

  useEffect(() => {
    getJraTahunOptions()
    getConfiguration()
  }, [props])

  const getJraTahunOptions = () => {
    Axios.get('/jra-tahun-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          jraTahunActiveOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getConfiguration = () => {
    Axios.get('/configuration/get', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        // console.log(res.data.JRA_TAHUN_ACTIVE)
        getJraTahunActive(res.data.JRA_TAHUN_ACTIVE)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getJraTahunActive = (uuid) => {
    Axios.get(`/jra-tahun/${uuid}`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        // {
        //   "code": "2019",
        //   "value": "a0c94f02de40400e97f7e28a3c3fe18d",
        //   "label": "JRA Tahun 2019"
        // },
        setState((prevProps) => ({
          ...prevProps,
          jraTahunActive: {
            code: res.data.code,
            value: res.data.id,
            label: res.data.name
          }
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeJraTahunActive = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      jraTahunActive: obj
    }))
  }

  const onSubmit = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/configuration/set',
      data: {
        JRA_TAHUN_ACTIVE: state.jraTahunActive.value
      }
    })
      .then(() => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
      })
      .catch(() => {
        Toastr.error('Failed!')
      })
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faWrench} data={['konfigurasi', 'aplikasi']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="text-capitalize">aplikasi</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row">
                    <Label md="3">Jadwal Retansi Aktif (Periode)</Label>
                    <Col md="4">
                      <Select
                        value={state.jraTahunActive}
                        onChange={handleChangeJraTahunActive}
                        options={state.jraTahunActiveOptions}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={_.isNull(state.jraTahunActive)}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(Configuration)
