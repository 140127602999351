// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faPencilAlt, faSquare, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Select from 'react-select'
import 'react-tabs/style/react-tabs.css'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader from 'components/Headers/AuthorizedHeader'
import UsulMusnahMetadata from 'components/UsulMusnahMetadata'
import PenyusutanUsulMusnahSerahDaftarBerkas from 'components/PenyusutanUsulMusnahSerahDaftarBerkas'
import LoadingContent from 'components/Utils/LoadingContent'
import PenyusutanUsulMusnahSerahDokumenRead from 'components/PenyusutanUsulMusnahSerahDokumenRead'
import PenyusutanUsulMusnahSerahDokumen from 'components/PenyusutanUsulMusnahSerahDokumen'
import PenyusutanDokumenFormUploadValidation from 'components/PenyusutanDokumenFormUploadValidation'

const UsulSerahFormDokumen = (props) => {
  UsulSerahFormDokumen.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    data: null,
    dokumenCheckedState: [],

    jenisDokumen: null,
    jenisDokumenOptions: null,
    file: null,
    isFileValid: false,

    defaultChecked: false,
    confirmnModal: null
  })

  useEffect(() => {
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
    getJenisDokumen()
  }, [props])

  const getData = (id) => {
    Axios.get(`/unit-kearsipan/penyusutan/usul-serah/${id}?include-dokumen=true&include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data,
          dokumenCheckedState: new Array(response.data.dokumen.length).fill(false)
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const getJenisDokumen = () => {
    Axios.get('/unit-kearsipan/penyusutan/jenis-dokumen-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          jenisDokumenOptions: response.data
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleChangeJenisDokumen = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      jenisDokumen: obj
    }))
  }

  const handleChangeFile = (getFile) => {
    if (_.isObject(getFile)) {
      if (getFile.target.files.length > 0) {
        const file = getFile.target.files[0]
        let isFileValid = false
        if (_.isEqual(file.type, 'application/pdf') && (file.size / 1024 / 1024) < 100) {
          isFileValid = true
        }
        setState((prevProps) => ({
          ...prevProps,
          file,
          isFileValid
        }))
      }
    }
  }

  const onSubmitUploadDokumen = () => {
    const formData = new FormData()
    formData.append('usulSerah', state.data.id)
    formData.append('jenisDokumen', state.jenisDokumen.value)
    formData.append('usulMusnah', state.data.id)
    formData.append('judul', state.data.judul)
    formData.append('file', state.file, state.file.name)
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/penyusutan/usul-serah/dokumen',
      data: formData
    })
      .then(() => {
        Toastr.success('Dokumen diunggah...')
        setState((prevProps) => ({
          ...prevProps,
          isUploadSuratUsulan: true,
          file: null,
          isFileValid: null,
          jenisDokumen: null
        }))
        getData(props.match.params.uid)
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  const handleOnCheckedDokumen = (position) => {
    // console.log(position);
    const tempState = []
    state.dokumenCheckedState.map((d, i) => {
      tempState.push(i === position ? !d : d)
      return tempState
    })
    setState((prevProps) => ({
      ...prevProps,
      dokumenCheckedState: tempState
    }))
  }

  const onClickUnSelectAllTrueFalse = (e) => {
    const value = e.currentTarget.getAttribute('data-value')
    setState((prevProps) => ({
      ...prevProps,
      dokumenCheckedState: new Array(state.data.dokumen.length).fill(_.isEqual(value, 'true'))
    }))
  }

  const onSubmitCloseConfirmModal = () => {
    setState((prevProps) => ({
      ...prevProps,
      confirmnModal: null
    }))
  }

  const onSubmitProsesRemoveDokumen = () => {
    let dokumenString = ''
    state.dokumenCheckedState.map((d, i) => {
      if (d) {
        dokumenString += `${(state.data.dokumen[i].id)},`
      }
      return dokumenString
    })
    console.log(`[${dokumenString}]`)
    // do remove
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: '/unit-kearsipan/penyusutan/usul-serah/dokumen',
      data: {
        dokumen: `[${dokumenString}]`
      }
    })
      .then(() => {
        Toastr.info('Sukses...')
        // end of
        setState((prevProps) => ({
          ...prevProps,
          confirmnModal: null
        }))
        getData(props.match.params.uid)
      })
      .catch(() => {
        Toastr.error('Gagal!')
      })
  }

  const onSubmitRemoveDokumen = () => {
    setState((prevProps) => ({
      ...prevProps,
      confirmnModal: (
        setState((prevProps) => ({
          ...prevProps,
          confirmnModal: (
            <ReactBSAlert
              warning
              style={{
                display: 'block'
              }}
              title="Hapus dokumen"
              onConfirm={onSubmitProsesRemoveDokumen}
              onCancel={onSubmitCloseConfirmModal}
              confirmBtnCssClass="success"
              cancelBtnBsStyle="warning"
              confirmBtnText="Ya, Hapus"
              cancelBtnText="Batal"
              showCancel
              btnSize="">Anda yakin ?
            </ReactBSAlert>
          )
        }))
      )
    }))
  }

  if (_.isNull(state.data) || _.isNull(state.jenisDokumenOptions)) {
    return <LoadingContent/>
  }

  return (
    <>
      {/* {console.log(state)} */}
      {state.confirmnModal}
      <AuthorizedHeader name="Penyusutan" parentName="Usul Musnah"/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-black-50 text-underline text-capitalize">dokumen</h1>
              </CardHeader>
              <CardBody>
                <Tabs defaultIndex={2}>
                  <TabList>
                    <Tab>Metadata</Tab>
                    <Tab>Daftar Berkas</Tab>
                    <Tab>Dokumen</Tab>
                  </TabList>
                  <TabPanel>
                    <Link
                      className="btn btn-default btn-sm float-left"
                      to={`/authorized/penyusutan/metadata/usul-serah/${state.data.id}`}>
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                      />
                    </Link>
                    <UsulMusnahMetadata data={state.data}/>
                  </TabPanel>
                  <TabPanel>
                    <Link
                      className="btn btn-primary btn-sm float-left"
                      to={`/authorized/penyusutan/daftar-berkas/usul-serah/${state.data.id}`}>
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                      />
                    </Link>
                    <PenyusutanUsulMusnahSerahDaftarBerkas data={state.data.daftarBerkas}/>
                  </TabPanel>
                  <TabPanel>
                    {state.data.apakahSudahSerah
                      ? <PenyusutanUsulMusnahSerahDokumen
                        usul={'serah'}
                        token={props.token}
                        data={state.data.dokumen}/>
                      : <>
                        <Form>
                          <FormGroup className="row pl-lg-4">
                            <Label md="2" className="text-capitalize">
                              jenis dokumen
                            </Label>
                            <Col md="7">
                              <Select
                                value={state.jenisDokumen}
                                onChange={handleChangeJenisDokumen}
                                options={state.jenisDokumenOptions}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row pl-lg-4">
                            <Label md="2" className="text-capitalize">
                              dokumen
                            </Label>
                            <Col md="7">
                              <Input
                                required
                                onChange={handleChangeFile}
                                placeholder="Select File"
                                type="file"
                              />
                              <FormText>format file yang diizinkan : pdf, max 100MB</FormText>
                              <PenyusutanDokumenFormUploadValidation
                                file={state.file}
                                isFileValid={state.isFileValid}
                              />
                              <Button
                                disabled={!state.isFileValid || _.isNull(state.jenisDokumen)}
                                onClick={onSubmitUploadDokumen}
                                color="primary"><FontAwesomeIcon icon={faUpload}/> Unggah</Button>
                            </Col>
                          </FormGroup>
                        </Form>
                        <Button
                          size="sm"
                          disabled={!_.includes(state.dokumenCheckedState, true)}
                          onClick={onSubmitRemoveDokumen}
                          color="danger"><FontAwesomeIcon icon={faTrash}/> Hapus</Button>
                        <Table bordered responsive>
                          <thead className="thead-light">
                          <tr>
                            <th className="col-md-1">
                              <a
                                style={{ cursor: 'pointer' }}
                                data-value={true}
                                onClick={onClickUnSelectAllTrueFalse}
                              ><FontAwesomeIcon icon={faCheckDouble} size={'lg'}/></a> | <a
                              style={{ cursor: 'pointer' }}
                              data-value={false}
                              onClick={onClickUnSelectAllTrueFalse}
                            ><FontAwesomeIcon icon={faSquare} size={'lg'}/></a>
                            </th>
                            <th className="col-md-1">jenis dokumen</th>
                            <th className="col-md-3">judul</th>
                            <th className="col-md-3">nama file</th>
                          </tr>
                          </thead>
                          <tbody className="list">
                          {state.data.dokumen.map((d, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-center">
                                  <Input
                                    type="checkbox"
                                    checked={state.dokumenCheckedState[i]}
                                    id={d.id}
                                    value={d.id}
                                    onChange={() => handleOnCheckedDokumen(i)}
                                  />
                                </td>
                                <td>{_.isNull(d.jenisDokumen) ? null : d.jenisDokumen.name}</td>
                                <td>{d.judul}</td>
                                <td>
                                  <PenyusutanUsulMusnahSerahDokumenRead
                                    fileName={d.fileName}
                                    fileType={d.fileType}
                                    url={`${Axios.defaults.baseURL}/unit-kearsipan/penyusutan/usul-serah/dokumen/read/${d.id}/${props.token}`}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </Table>
                      </>
                    }
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulSerahFormDokumen)
