// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faBolt, faCheck, faCheckCircle, faMinusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import LoadingContent from 'components/Utils/LoadingContent'
import UsulPindahSuratUsulan from 'components/Utils/UsulPindahSuratUsulan'
import LabelApakahSudahDikirimKeUnitKearsipan from 'components/Utils/LabelApakahSudahDikirimKeUnitKearsipan'
import LabelApakahSudahLulusVerifikasi from 'components/Utils/LabelApakahSudahLulusVerifikasi'
import IconBoolean from 'components/Utils/IconBoolean'

const UsulPindahVerifikasi = (props) => {
  UsulPindahVerifikasi.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    data: null,
    daftarBerkas: [],
    alertModal: null,

    nomorFolderPengecekan: '',

    verifikasiModal: false,
    alasanTolak: '',
    isVerifikasiDitolakAction: false,
    isVerifikasiDisetujuiAction: false,

    prosesPindahModal: false,
    isProsesPindahAction: false
  })

  useEffect(() => {
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
  }, [props])

  const getData = (id) => {
    Axios.get(`/unit-kearsipan/usul-pindah/${id}?include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data,
          daftarBerkas: response.data.daftarBerkas
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onResetConfirmModal = () => {
    setState((prevProps) => ({
      ...prevProps,
      alertModal: null
    }))
  }

  const onClickVerifikasiTolak = () => {
    setState((prevProps) => ({
      ...prevProps,
      verifikasiModal: !state.verifikasiModal,
      alasanTolak: ''
    }))
  }

  const onChangeAlasanTolak = (obj) => {
    const alasanTolak = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      alasanTolak
    }))
  }

  const onSubmitVerifikasiTolak = () => {
    Toastr.info(`${state.data.judul}, ditolak...`)
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/verifikasi/usul-pindah/tolak',
      data: {
        usulPindahUuid: props.match.params.uid,
        alasanVerifikasiDitolak: state.alasanTolak
      }
    })
      .then(() => {
        setState((prevProps) => ({
          ...prevProps,
          isVerifikasiDitolakAction: true
        }))
      })
      .catch(() => {
        Toastr.error('Verifikasi Gagal!')
      })
  }

  const onClickModalVerifikasiSetujui = () => {
    if (!_.includes(state.data.daftarBerkasOnUnitKearsipan, true)) {
      setState((prevProps) => ({
        ...prevProps,
        alertModal: (
          <ReactBSAlert
            warning
            style={{
              display: 'block'
            }}
            title="Setujui Verifikasi Persyaratan"
            onConfirm={onSubmitVerifikasiPersyaratanSetujui}
            onCancel={onResetConfirmModal}
            confirmBtnCssClass="success"
            cancelBtnBsStyle="warning"
            confirmBtnText="Ya, Kirim"
            cancelBtnText="Batal"
            showCancel
            btnSize="">
            Anda yakin ?
          </ReactBSAlert>
        )
      }))
    } else {
      // console.log('reject')
      setState((prevProps) => ({
        ...prevProps,
        alertModal: (
          <ReactBSAlert
            info
            style={{ display: 'block' }}
            title="Persetujuan Verfikasi Gagal"
            onConfirm={onResetConfirmModal}
            onCancel={onResetConfirmModal}
            confirmBtnBsStyle="info"
            confirmBtnText="Ok"
            btnSize="">
            Ada sebagian/semua <em>Daftar Berkas</em> terpilih sudah tersedia pada Unit Kearsipan
          </ReactBSAlert>
        )
      }))
    }
  }

  const onSubmitVerifikasiPersyaratanSetujui = () => {
    Toastr.info(`${state.data.judul}, disetujui...`)
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/verifikasi/usul-pindah/setujui',
      data: {
        usulPindahUuid: props.match.params.uid
      }
    })
      .then(() => {
        setState((prevProps) => ({
          ...prevProps,
          isVerifikasiDitolakAction: true
        }))
      })
      .catch(() => {
        Toastr.error('Verifikasi Persyaratan Gagal!')
      })
  }

  const onClickModalProsesPindahModal = () => {
    setState((prevProps) => ({
      ...prevProps,
      alertModal: (
        setState((prevProps) => ({
          ...prevProps,
          alertModal: (
            <ReactBSAlert
              warning
              style={{
                display: 'block'
              }}
              title="Proses Usul Pindah"
              onConfirm={onSubmitProsesPindah}
              onCancel={onResetConfirmModal}
              confirmBtnCssClass="success"
              cancelBtnBsStyle="warning"
              confirmBtnText="Ya, Kirim"
              cancelBtnText="Batal"
              showCancel
              btnSize="">
              Proses ini akan melakukan perpindahan dan duplikasi Daftar Berkas Unit Pengolah
              menuju Unit Kearsipan.
              <br/> Anda yakin ?
            </ReactBSAlert>
          )
        }))
      )
    }))
  }

  const onSubmitProsesPindah = () => {
    Toastr.info('Proses pindah...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/usul-pindah/proses-pindah',
      data: {
        usulPindahUuid: props.match.params.uid
      }
    })
      .then(() => {
        setState((prevProps) => ({
          ...prevProps,
          isProsesPindahAction: true
        }))
      })
      .catch(() => {
        Toastr.error('Proses pindah gagal!')
      })
  }

  const onChangeNomorFolderPengecekan = (obj) => {
    const nomorFolderPengecekan = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      nomorFolderPengecekan
    }))
  }

  const onSubmitPengecekanFisik = () => {
    Toastr.info('Pengecekan Fisik Daftar Berkas')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/usul-pindah/pengecekan-fisik-daftar-berkas',
      data: {
        usulPindahId: props.match.params.uid,
        nomorFolder: state.nomorFolderPengecekan,
        unitPengolahId: state.data.unitPengolah.id
      }
    })
      .then(() => {
        Toastr.success(`${state.nomorFolderPengecekan} terkonfirmasi`)
        getDaftarBerkas()
      })
      .catch(err => {
        if (_.isEqual(err.response.status, 404)) {
          Toastr.warning(err.response.data.detail)
        }
      })
  }

  const getDaftarBerkas = () => {
    Axios.get(`/unit-kearsipan/usul-pindah/${state.data.id}?include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          daftarBerkas: response.data.daftarBerkas
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  if (_.isNull(state.data)) {
    return <LoadingContent/>
  }

  if (state.isVerifikasiDitolakAction) {
    return <Redirect to={'/authorized/in/usul-pindah/verifikasi'}/>
  }

  if (state.isProsesPindahAction) {
    return <Redirect to={'/authorized/in/usul-pindah/sudah-pindah'}/>
  }

  return (
    <>
      {/* {console.log(state)} */}
      {state.alertModal}
      <AuthorizedHeader2 icon={faCheckCircle} data={['verifikasi usul pindah', 'syarat & berkas']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <Link className="btn btn-primary" to="/authorized/in/usul-pindah/verifikasi">
                  <i className="fas fa-bars"/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                  <tr>
                    <td className="text-right col-md-3">ID :</td>
                    <td><code className="text-lg">{state.data.shortid}</code></td>
                  </tr>
                  <tr>
                    <td className="text-right">Judul :</td>
                    <td><h5>{state.data.judul}</h5></td>
                  </tr>
                  <tr>
                    <td className="text-right">Daftar Berkas:</td>
                    <td>
                      {_.isBoolean(state.data.apakahSudahLulusVerifikasi)
                        ? null
                        : <>
                          <Row className="row-example">
                            <Col sm={5}>
                              <Input
                                placeholder="Masukkan nomor folder"
                                value={state.nomorFolderPengecekan}
                                onChange={onChangeNomorFolderPengecekan}
                              />
                            </Col>
                            <Col sm>
                              <Button
                                disabled={_.isEmpty(state.nomorFolderPengecekan)}
                                className="text-capitalize"
                                color={'primary'}
                                onClick={onSubmitPengecekanFisik}
                              ><FontAwesomeIcon icon={faSearch}/> pengecekan fisik</Button>
                            </Col>
                          </Row>
                          <p/>
                        </>
                      }
                      <Table bordered responsive>
                        <thead className="thead-light">
                        <tr>
                          <th className="text-capitalize">nomor folder</th>
                          <th className="text-capitalize">nama berkas</th>
                          <th className="text-capitalize">pengecekan fisik <br />daftar berkas</th>
                        </tr>
                        </thead>
                        <tbody className="list">
                        {state.daftarBerkas.map((d, i) => {
                          return (
                            <tr key={i}>
                              <td className="col-md-1">{d.nomorFolder}</td>
                              <td>{d.judul}</td>
                              <td><IconBoolean value={d.apakahSudahLulusPengecekan}/></td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Surat usulan :</td>
                    <td>
                      {_.isEmpty(state.data.suratUsulanFileName)
                        ? null
                        : <UsulPindahSuratUsulan token={props.token} data={state.data}/>}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Waktu kirim ke unit kearsipan:</td>
                    <td>
                      {_.isNull(state.data.apakahSudahDikirimKeUnitKearsipan)
                        ? '-'
                        : <LabelApakahSudahDikirimKeUnitKearsipan
                          apakahSudahDikirimKeUnitKearsipan={state.data.apakahSudahDikirimKeUnitKearsipan}
                          tanggalDikirimKeUnitKearsipan={state.data.tanggalDikirimKeUnitKearsipan}
                        />
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Verifikasi :</td>
                    <td>
                      {_.isBoolean(state.data.apakahSudahLulusVerifikasi)
                        ? <LabelApakahSudahLulusVerifikasi
                          apakahSudahLulusVerifikasi={state.data.apakahSudahLulusVerifikasi}
                          tanggalVerifikasi={state.data.tanggalVerifikasi}
                          alasanVerifikasiDitolak={state.data.alasanVerifikasiDitolak}
                        />
                        : null
                      }
                      {_.isNull(state.data.apakahSudahLulusVerifikasi)
                        ? <>
                          <Button
                            onClick={onClickModalVerifikasiSetujui}
                            disabled={!state.data.apakahSudahDikirimKeUnitKearsipan &&
                            state.data.apakahSudahLulusVerifikasi}
                            color="success"><FontAwesomeIcon icon={faCheck}/> SETUJUI</Button>
                          <Button
                            onClick={onClickVerifikasiTolak}
                            disabled={!state.data.apakahSudahDikirimKeUnitKearsipan &&
                            state.data.apakahSudahLulusVerifikasi}
                            color="danger"><FontAwesomeIcon icon={faMinusCircle}/> TOLAK</Button>
                          <Modal
                            className="modal-dialog-centered"
                            size="lg"
                            isOpen={state.verifikasiModal}
                            toggle={onClickVerifikasiTolak}
                          >
                            <ModalHeader>
                              Alasan Penolakkan Verifikasi
                            </ModalHeader>
                            <ModalBody>
                              <Form>
                                <Input
                                  value={state.alasanTolak}
                                  onChange={onChangeAlasanTolak}
                                />
                              </Form>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                onClick={onClickVerifikasiTolak}
                                color="warning">Batal</Button>
                              <Button
                                disabled={_.isEmpty(state.alasanTolak)}
                                onClick={onSubmitVerifikasiTolak}
                                color="primary">Ya, Kirim</Button>
                            </ModalFooter>
                          </Modal>
                        </>
                        : null
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Proses Pindah :</td>
                    <td>
                      {
                        state.data.apakahSudahLulusVerifikasi
                          ? <>
                            <Button
                              onClick={onClickModalProsesPindahModal}
                              className="text-uppercase"
                              color="primary"><FontAwesomeIcon icon={faBolt}/> proses pindah</Button>
                          </>
                          : null
                      }
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulPindahVerifikasi)
