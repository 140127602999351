// /* eslint-disable */
import React from 'react'
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const LoadingContent = () => {
  return (
    <>
      <AuthorizedHeader2 icon={faSpinner} data={['loading...']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default LoadingContent
