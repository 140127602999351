import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import Toastr from 'toastr'
import { Alert, Card, CardBody, CardHeader, Container, Row } from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'
import AuthService from './AuthService'
import Axios from 'helpers/Axios'
import Header from 'components/Headers/BlankHeader'

const Auth = new AuthService()

export default function ValidationUnitPengolah (AuthComponent) {
  const TemplateView = (props) => {
    TemplateView.propTypes = {
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }
    return (
      <>
        <Header/>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                </CardHeader>
                <CardBody>
                  <Alert color={props.color}>{props.label}</Alert>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }

  const Authenticated = (props) => {
    const [state, setState] = useState({
      isTokenValid: null,
      profile: {},
      isRedirected: false
    })

    useEffect(() => {
      getData()
    }, [])

    const getData = () => {
      Axios.get('/profile', {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`
        }
      })
        .then(res => {
          setState((prevProps) => ({
            ...prevProps,
            isTokenValid: true,
            profile: res.data
          }))
        })
        .catch(() => {
          setState((prevProps) => ({
            ...prevProps,
            isTokenValid: false
          }))
        })
    }

    const onClickLogout = () => {
      Toastr.info('Relogin...')
      Auth.logout()
      setState((prevProps) => ({
        ...prevProps,
        isRedirected: true
      }))
    }

    if (_.has(state.profile, 'isUnitKearsipan')) {
      if (!state.profile.isUnitKearsipan) {
        return (
          <Redirect to="/"/>
        )
      }
    }

    if (state.isRedirected) {
      return <Redirect to="/"/>
    }

    if (_.isBoolean(state.isTokenValid) && !state.isTokenValid) {
      return (
        <>
          <Header/>
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                  </CardHeader>
                  <CardBody>
                    <Link to={'#'}>
                      <Alert color="danger" onClick={onClickLogout}>Session anda sudah berakhir!. Klik disini
                        untuk
                        login ulang</Alert>
                    </Link>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      )
    }

    if (_.isBoolean(state.isTokenValid)) {
      return (
        <>
          <AuthComponent
            {...props}
            auth={Auth}
            token={Auth.getToken()}
            profile={state.profile}
          />
        </>
      )
    }

    return (
      <>
        <TemplateView
          color={'default'}
          label={'Loading...'}/>
      </>
    )
  }

  return Authenticated
}
