import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Select from 'react-select'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faTable } from '@fortawesome/free-solid-svg-icons'

const KategoriForm = (props) => {
  KategoriForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedOrUpdateData: false,

    id: null,
    tahun: null,
    code: '',
    name: '',

    tahunOptions: [],
    isModalOpen: false
  })

  useEffect(() => {
    getTahunOptions()
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getTahunOptions = () => {
    Axios.get('/jra-tahun-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          tahunOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (token, id) => {
    Axios.get(`/jra-kategori/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          id: res.data.id,
          code: res.data.code,
          name: res.data.name,
          tahun: _.isNull(res.data.tahun)
            ? null
            : {
                value: res.data.tahun.id,
                label: `[${res.data.tahun.code}] ${res.data.tahun.name}`
              }
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeCode = (obj) => {
    const code = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      code
    }))
  }

  const handleChangeTahun = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      tahun: obj
    }))
  }

  const handleChangeName = (obj) => {
    const name = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      name
    }))
  }

  const onSubmit = () => {
    // Toastr.info('Menyimpan data...');
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.id) ? 'POST' : 'PATCH',
      url: _.isNull(state.id) ? '/jra-kategori' : `/jra-kategori/${state.id}`,
      data: {
        code: state.code,
        name: state.name,
        tahun: _.isObject(state.tahun) ? state.tahun.value : null
      }
    })
      .then(() => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        Toastr.error('Gagal!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Konflik!')
        }
      })
  }

  const toggleModalDelete = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  const onDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/jra-kategori/${state.id}`,
      data: {}
    })
      .then(res => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Data tidak bisa dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
  }

  if (state.isSavedOrUpdateData) {
    return <Redirect to={'/authorized/jra/kategori'}/>
  }

  return (
    <>
        {/* { console.log(state) } */}
      <AuthorizedHeader2 icon={faTable} data={['jadwal retensi aktif', 'kategori']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader>
                <Link className="btn btn-primary" to="/authorized/jra/kategori">
                  <i className="fas fa-bars"/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                {_.isNull(state.id)
                  ? null
                  : (
                  <Alert color="warning">Anda akan mengubah data {state.name}</Alert>
                    )}
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="5">
                        <FormGroup>
                          <Label>Tahun (Periode)</Label>
                          <Select
                            value={state.tahun}
                            onChange={handleChangeTahun}
                            options={state.tahunOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="5">
                        <FormGroup>
                          <Label>Kode</Label>
                          <Input
                            value={state.code}
                            onChange={handleChangeCode}
                            placeholder="Kode"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="5">
                        <FormGroup>
                          <Label>Nama</Label>
                          <Input
                            value={state.name}
                            onChange={handleChangeName}
                            placeholder="Nama"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={(_.isEmpty(state.code) || _.isEmpty(state.name) || _.isNull(state.tahun))}
                  color='primary'
                  onClick={onSubmit}
                >Simpan</Button>
                {_.isNull(state.id)
                  ? null
                  : (
                  <Button color='danger' onClick={toggleModalDelete}>
                    Hapus
                  </Button>
                    )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalOpen}
        backdrop='static' centered={true}
        toggle={toggleModalDelete}
      >
        <ModalHeader
          toggle={toggleModalDelete}
        >Konfirmasi Hapus Data</ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onDelete}
          >YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModalDelete}
          >Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ValidationAuthorized(KategoriForm)
