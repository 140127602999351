// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import Toastr from 'toastr'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader from 'components/Headers/AuthorizedHeader'

const UsulSerahFormStep1 = (props) => {
  UsulSerahFormStep1.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedData: false,
    uid: null,
    shortid: '[Auto]',
    judul: '',
    deskripsi: ''
  })

  useEffect(() => {
  }, [props])

  const handleChangeJudul = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      judul: obj.target.value
    }))
  }

  const handleChangeDeskripsi = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      deskripsi: obj.target.value
    }))
  }

  const onSubmit = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-kearsipan/penyusutan/usul-serah',
      data: {
        judul: state.judul,
        deskripsi: state.deskripsi
      }
    })
      .then((response) => {
        Toastr.success('Menuju halaman selanjutnya...')
        setState((prevProps) => ({
          ...prevProps,
          uid: response.data.detail,
          isSavedData: !!_.isNull(state.uid)
        }))
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  if (state.isSavedData || state.isUpdatedData) {
    return <Redirect to={`/authorized/penyusutan/daftar-berkas/usul-serah/${state.uid}`}/>
  }

  return (
    <>
      {/* { console.log(state) } */}
      <AuthorizedHeader name="Penyusutan" parentName="Usul Serah"/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-black-50 text-underline text-capitalize">membuat metadata; judul, deskripsi</h1>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row pl-lg-4">
                    <Label md="2">
                      ID
                    </Label>
                    <Col md="8">
                      <Input
                        readOnly={true}
                        value={state.shortid}
                        placeholder="ID"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row pl-lg-4">
                    <Label md="2">
                      Judul
                    </Label>
                    <Col md="8">
                      <Input
                        value={state.judul}
                        onChange={handleChangeJudul}
                        placeholder="Judul"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row pl-lg-4">
                    <Label md="2">
                      Deskripsi
                    </Label>
                    <Col md="8">
                      <Input
                        value={state.deskripsi}
                        onChange={handleChangeDeskripsi}
                        placeholder="Deskripsi"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={_.isEqual(state.judul, '')}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faArrowRight}/> Selanjutnya</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulSerahFormStep1)
