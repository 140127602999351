// /* eslint-disable */
import Blank from 'views/pages/Blank'
import Rekapitulasi from 'views/pages/Authorized/Dashboard/Rekapitulasi'
import Profile from 'views/pages/Authorized/Sistem/Profile'
import GantiPassword from 'views/pages/Authorized/Sistem/GantiPassword'
import Configuration from 'views/pages/Authorized/Sistem/Configuration'

// master
import BahasaTulisanDataGrid from 'views/pages/Authorized/Master/BahasaTulisanDataGrid'
import BahasaTulisanForm from 'views/pages/Authorized/Master/BahasaTulisanForm'
import JenisDokumenDataGrid from 'views/pages/Authorized/Master/JenisDokumenDataGrid'
import JenisDokumenForm from 'views/pages/Authorized/Master/JenisDokumenForm'
import JenisMediaPenyimpananDataGrid from 'views/pages/Authorized/Master/JenisMediaPenyimpananDataGrid'
import JenisMediaPenyimpananForm from 'views/pages/Authorized/Master/JenisMediaPenyimpananForm'
import KlasifikasiAksesDataGrid from 'views/pages/Authorized/Master/KlasifikasiAksesDataGrid'
import KlasifikasiAksesForm from 'views/pages/Authorized/Master/KlasifikasiAksesForm'
import KlasifikasiKeamananDataGrid from 'views/pages/Authorized/Master/KlasifikasiKeamananDataGrid'
import KlasifikasiKeamananForm from 'views/pages/Authorized/Master/KlasifikasiKeamananForm'
import KondisiArsipDataGrid from 'views/pages/Authorized/Master/KondisiArsipDataGrid'
import KondisiArsipForm from 'views/pages/Authorized/Master/KondisiArsipForm'
import TingkatPerkembanganDataGrid from 'views/pages/Authorized/Master/TingkatPerkembanganDataGrid'
import TingkatPerkembanganForm from 'views/pages/Authorized/Master/TingkatPerkembanganForm'
import UnitKerjaDataGrid from 'views/pages/Authorized/Master/UnitKerjaDataGrid'
import UnitKerjaForm from 'views/pages/Authorized/Master/UnitKerjaForm'
import UnitPengolahDataGrid from 'views/pages/Authorized/Master/UnitPengolahDataGrid'
import UnitPengolahForm from 'views/pages/Authorized/Master/UnitPengolahForm'

import TahunDataGrid from 'views/pages/Authorized/Jra/TahunDataGrid'
import TahunForm from 'views/pages/Authorized/Jra/TahunForm'
import KategoriDataGrid from 'views/pages/Authorized/Jra/KategoriDataGrid'
import KategoriForm from 'views/pages/Authorized/Jra/KategoriForm'
import SubKategoriDataGrid from 'views/pages/Authorized/Jra/SubKategoriDataGrid'
import SubKategoriForm from 'views/pages/Authorized/Jra/SubKategoriForm'
import KlasifikasiDataGrid from 'views/pages/Authorized/Jra/KlasifikasiDataGrid'
import KlasifikasiForm from 'views/pages/Authorized/Jra/KlasifikasiForm'

import ArsipUPDaftarBerkasDataGridSE from 'views/pages/Authorized/ArsipUP/ArsipUPDaftarBerkasDataGridSE'
import ArsipUnitPengolahDaftarBerkasForm from 'views/pages/Authorized/ArsipUP/DaftarBerkasForm'
import ArsipUPDaftarIsiBerkasDataGrid from 'views/pages/Authorized/ArsipUP/ArsipUPDaftarIsiBerkasDataGrid'
import DaftarIsiBerkasForm from 'views/pages/Authorized/ArsipUP/DaftarIsiBerkasForm'

import ArsipUKDaftarBerkasDataGrid from 'views/pages/Authorized/ArsipUK/ArsipUKDaftarBerkasDataGrid'
import DaftarBerkasForm from 'views/pages/Authorized/ArsipUK/DaftarBerkasForm'
import ArsipUKDaftarIsiBerkasDataGrid from 'views/pages/Authorized/ArsipUK/ArsipUKDaftarIsiBerkasDataGrid'
import RakDataGrid from 'views/pages/Authorized/ArsipUK/RakDataGrid'
import RakForm from 'views/pages/Authorized/ArsipUK/RakForm'

import PeminjamDataGrid from 'views/pages/Authorized/Sirkulasi/PeminjamDataGrid'
import PeminjamForm from 'views/pages/Authorized/Sirkulasi/PeminjamForm'
import TransaksiPeminjamanDataGrid from 'views/pages/Authorized/Sirkulasi/TransaksiPeminjamanDataGrid'
import TransaksiPeminjamanForm from 'views/pages/Authorized/Sirkulasi/TransaksiPeminjamanForm'

import UsulPindahDataGrid from 'views/pages/Authorized/In/UsulPindahDataGrid'
import UsulPindahVerifikasi from 'views/pages/Authorized/In/UsulPindahVerifikasi'
import UsulPindahSudahProsesDataGrid from 'views/pages/Authorized/In/UsulPindahSudahProsesDataGrid'

import UsulMusnahDataGrid from 'views/pages/Authorized/Penyusutan/UsulMusnahDataGrid'
import UsulMusnahFormMetadata from 'views/pages/Authorized/Penyusutan/UsulMusnahFormMetadata'
import UsulMusnahFormDaftarBerkas from 'views/pages/Authorized/Penyusutan/UsulMusnahFormDaftarBerkas'
import UsulMusnahFormDokumen from 'views/pages/Authorized/Penyusutan/UsulMusnahFormDokumen'
import UsulMusnahFormStep1 from 'views/pages/Authorized/Penyusutan/UsulMusnahFormStep1'

import UsulSerahDataGrid from 'views/pages/Authorized/Penyusutan/UsulSerahDataGrid'
import UsulSerahFormStep1 from 'views/pages/Authorized/Penyusutan/UsulSerahFormStep1'
import UsulSerahFormMetadata from 'views/pages/Authorized/Penyusutan/UsulSerahFormMetadata'
import UsulSerahFormDaftarBerkas from 'views/pages/Authorized/Penyusutan/UsulSerahFormDaftarBerkas'
import UsulSerahFormDokumen from 'views/pages/Authorized/Penyusutan/UsulSerahFormDokumen'

import UserUnitPengolahDataGrid from 'views/pages/Authorized/Users/UserUnitPengolahDataGrid'
import UserUnitPengolahForm from 'views/pages/Authorized/Users/UserUnitPengolahForm'

const routesAuthorized = [
  {
    collapse: true,
    name: 'Beranda',
    icon: 'fa fa-tachometer-alt',
    state: 'DashboardssCollapse',
    views: [
      {
        path: '/dashboard/rekapitulasi',
        name: 'Rekapitulasi',
        miniName: 'D',
        component: Rekapitulasi,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Master',
    icon: 'fa fa-cogs',
    state: 'masterCollapse',
    views: [
      {
        path: '/master/unit-kerja',
        name: 'Unit Kerja',
        miniName: 'UK',
        component: UnitKerjaDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/unit-kerja',
        component: UnitKerjaForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/unit-kerja/:uid?',
        component: UnitKerjaForm,
        layout: '/authorized'
      },
      {
        path: '/master/unit-pengolah',
        name: 'Unit Pengolah',
        miniName: 'UP',
        component: UnitPengolahDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/unit-pengolah',
        component: UnitPengolahForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/unit-pengolah/:uid?',
        component: UnitPengolahForm,
        layout: '/authorized'
      },
      {
        path: '/master/jenis-media-penyimpanan',
        name: 'Jenis Media Penyimpanan',
        miniName: 'JMP',
        component: JenisMediaPenyimpananDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/jenis-media-penyimpanan',
        component: JenisMediaPenyimpananForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/jenis-media-penyimpanan/:uid?',
        component: JenisMediaPenyimpananForm,
        layout: '/authorized'
      },
      {
        path: '/master/klasifikasi-akses',
        name: 'Klasifikasi Akses',
        miniName: 'KA',
        component: KlasifikasiAksesDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/klasifikasi-akses',
        component: KlasifikasiAksesForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/klasifikasi-akses/:uid?',
        component: KlasifikasiAksesForm,
        layout: '/authorized'
      },
      {
        path: '/master/klasifikasi-keamanan',
        name: 'Klasifikasi Keamanan',
        miniName: 'KK',
        component: KlasifikasiKeamananDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/klasifikasi-keamanan',
        component: KlasifikasiKeamananForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/klasifikasi-keamanan/:uid?',
        component: KlasifikasiKeamananForm,
        layout: '/authorized'
      },
      {
        path: '/master/kondisi-arsip',
        name: 'Kondisi Arsip',
        miniName: 'KAR',
        component: KondisiArsipDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/kondisi-arsip',
        component: KondisiArsipForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/kondisi-arsip/:uid?',
        component: KondisiArsipForm,
        layout: '/authorized'
      },
      {
        path: '/master/tingkat-perkembangan',
        name: 'Tingkat Perkembangan',
        miniName: 'TP',
        component: TingkatPerkembanganDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/tingkat-perkembangan',
        component: TingkatPerkembanganForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/tingkat-perkembangan/:uid?',
        component: TingkatPerkembanganForm,
        layout: '/authorized'
      },
      {
        path: '/master/bahasa-tulisan',
        name: 'Bahasa Tulisan',
        miniName: 'TP',
        component: BahasaTulisanDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/bahasa-tulisan',
        component: BahasaTulisanForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/bahasa-tulisan/:uid?',
        component: BahasaTulisanForm,
        layout: '/authorized'
      },
      {
        path: '/master/jenis-dokumen',
        name: 'Jenis Dokumen',
        miniName: 'JD',
        component: JenisDokumenDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/master/jenis-dokumen',
        component: JenisDokumenForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/master/jenis-dokumen/:uid?',
        component: JenisDokumenForm,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Jadwal Retensi Aktif',
    icon: 'fa fa-table',
    state: 'jraCollapse',
    views: [
      {
        path: '/jra/tahun',
        name: 'Tahun (Periode)',
        miniName: 'JRA-T',
        component: TahunDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/jra/tahun',
        component: TahunForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/jra/tahun/:uid?',
        component: TahunForm,
        layout: '/authorized'
      },
      {
        path: '/jra/kategori',
        name: 'Kategori',
        miniName: 'JRA-K',
        component: KategoriDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/jra/kategori',
        component: KategoriForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/jra/kategori/:uid?',
        component: KategoriForm,
        layout: '/authorized'
      },
      {
        path: '/jra/sub-kategori',
        name: 'Sub Kategori',
        miniName: 'JRA-SK',
        component: SubKategoriDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/jra/sub-kategori',
        component: SubKategoriForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/jra/sub-kategori/:uid?',
        component: SubKategoriForm,
        layout: '/authorized'
      },
      {
        path: '/jra/klasifikasi',
        name: 'Klasifikasi',
        miniName: 'JRA-KL',
        component: KlasifikasiDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/jra/klasifikasi',
        component: KlasifikasiForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/jra/klasifikasi/:uid?',
        component: KlasifikasiForm,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Arsip Unit Pengolah',
    icon: 'fa fa-folder-open',
    state: 'ArsipUnitPengolahCollapse',
    views: [
      {
        path: '/arsip-unit-pengolah/daftar-berkas',
        name: 'Daftar Berkas',
        miniName: 'AUP-DB',
        component: ArsipUPDaftarBerkasDataGridSE,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/arsip-unit-pengolah/daftar-berkas/:uid?',
        component: ArsipUnitPengolahDaftarBerkasForm,
        layout: '/authorized'
      },
      {
        path: '/arsip-unit-pengolah/daftar-isi-berkas',
        name: 'Daftar Isi Berkas',
        miniName: 'AUP-DIB',
        component: ArsipUPDaftarIsiBerkasDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/arsip-unit-pengolah/daftar-isi-berkas/:uid?',
        component: DaftarIsiBerkasForm,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Arsip Unit Kearsipan',
    icon: 'fa fa-archive',
    state: 'ArsipUnitKearsipanCollapse',
    views: [
      {
        path: '/arsip-uk/daftar-berkas',
        name: 'Daftar Berkas',
        miniName: 'UPP',
        component: ArsipUKDaftarBerkasDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/arsip-uk/daftar-berkas/:uid?',
        component: DaftarBerkasForm,
        layout: '/authorized'
      },
      {
        path: '/arsip-uk/daftar-isi-berkas',
        name: 'Daftar Isi Berkas',
        miniName: 'UPP',
        component: ArsipUKDaftarIsiBerkasDataGrid,
        layout: '/authorized'
      },
      {
        path: '/arsip-uk/rak',
        name: 'Rak',
        miniName: 'UPR',
        component: RakDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/arsip-uk/rak',
        component: RakForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/arsip-uk/rak/:uid?',
        component: RakForm,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Sirlukasi',
    icon: 'fa fa-shopping-bag',
    state: 'Sirlukasi',
    views: [
      {
        path: '/sirlukasi/peminjam',
        name: 'Peminjam',
        miniName: 'P',
        component: PeminjamDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/sirlukasi/peminjam',
        component: PeminjamForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/sirlukasi/peminjam/:uid?',
        component: PeminjamForm,
        layout: '/authorized'
      },
      {
        path: '/sirlukasi/transaksi-peminjaman',
        name: 'Transaksi Peminjaman',
        miniName: 'TP',
        component: TransaksiPeminjamanDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/sirlukasi/transaksi-peminjaman',
        component: TransaksiPeminjamanForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/sirlukasi/transaksi-peminjaman/:uid?',
        component: TransaksiPeminjamanForm,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Verifikasi Usul Pindah',
    icon: 'fa fa-check-circle',
    state: 'VerifikasiCollapse',
    views: [
      {
        path: '/in/usul-pindah/verifikasi',
        name: 'Verifikasi Syarat & Berkas',
        miniName: 'UPP',
        component: UsulPindahDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/detail/in/usul-pindah/:uid?',
        component: UsulPindahVerifikasi,
        layout: '/authorized'
      },
      {
        path: '/in/usul-pindah/sudah-pindah',
        name: 'Sudah Pindah',
        miniName: 'UPP',
        component: UsulPindahSudahProsesDataGrid,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Penyusutan',
    icon: 'fa fa-truck',
    state: 'PenyusutanCollapse',
    views: [
      {
        path: '/penyusutan/usul-musnah',
        name: 'Usul Musnah',
        miniName: 'PUM',
        component: UsulMusnahDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/add/usul-musnah',
        component: UsulMusnahFormStep1,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/metadata/usul-musnah/:uid?',
        component: UsulMusnahFormMetadata,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/daftar-berkas/usul-musnah/:uid?',
        component: UsulMusnahFormDaftarBerkas,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/dokumen/usul-musnah/:uid?',
        component: UsulMusnahFormDokumen,
        layout: '/authorized'
      },
      {
        path: '/penyusutan/usul-serah',
        name: 'Usul Serah',
        miniName: 'PUS',
        component: UsulSerahDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/add/usul-serah',
        component: UsulSerahFormStep1,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/metadata/usul-serah/:uid?',
        component: UsulSerahFormMetadata,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/daftar-berkas/usul-serah/:uid?',
        component: UsulSerahFormDaftarBerkas,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/dokumen/usul-serah/:uid?',
        component: UsulSerahFormDokumen,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Pengguna',
    icon: 'fa fa-users',
    state: 'userCollapse',
    views: [
      {
        path: '/user/unit-pengolah',
        name: 'Unit Pengolah',
        miniName: 'UUP',
        component: UserUnitPengolahDataGrid,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/user/unit-pengolah',
        component: UserUnitPengolahForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/user/unit-pengolah/:uid?',
        component: UserUnitPengolahForm,
        layout: '/authorized'
      },
      {
        path: '/user/unit-kearsipan',
        name: 'Unit Kearsipan',
        miniName: 'UUK',
        component: Blank,
        layout: '/authorized'
      },
      {
        path: '/user/eksekutif',
        name: 'Eksekutif',
        miniName: 'UE',
        component: Blank,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Konfigurasi',
    icon: 'fa fa-wrench',
    state: 'SistemCollapse',
    views: [
      {
        path: '/sistem/aplikasi',
        name: 'Aplikasi',
        miniName: 'D',
        component: Configuration,
        layout: '/authorized'
      },
      {
        path: '/sistem/profile',
        name: 'Profil',
        miniName: 'D',
        component: Profile,
        layout: '/authorized'
      },
      {
        path: '/sistem/ganti-password',
        name: 'Ganti Kata Kunci',
        miniName: 'D',
        component: GantiPassword,
        layout: '/authorized'
      }
    ]
  }
]

export default routesAuthorized
