// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'

const ArsipUkIsiBerkas = (props) => {
  ArsipUkIsiBerkas.propTypes = {
    data: PropTypes.array.isRequired
  }

  return (
    <>
      {/* {console.log(props.data)} */}
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
        <tr>
          <th width="1%">no</th>
          <th>nomor dokumen</th>
          <th>judul</th>
        </tr>
        </thead>
        <tbody className="list">
        {props.data.length === 0
          ? <tr>
            <td colSpan={3}><code>...</code></td>
          </tr>
          : null}
        {props.data.map((d, i) => {
          return (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{d.nomorDokumen}</td>
              <td>{d.judul}</td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    </>
  )
}
export default ArsipUkIsiBerkas
