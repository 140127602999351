// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Switch from 'react-switch'
import Select from 'react-select'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Toastr from 'toastr'
import { format, parseISO } from 'date-fns'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import DatetimeFormatV2 from 'components/Utils/DatetimeFormatV2'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import { faFolderOpen, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ArsipUnitPengolahDaftarBerkasForm = (props) => {
  ArsipUnitPengolahDaftarBerkasForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedOrUpdateData: false,
    isValidData: false,
    isMinimalReq: false,
    nomorFolderExist: false,

    uid: null,
    judul: '',
    kurunWaktuMulai: '',
    kurunWaktuSelesai: '',
    jraKlasifikasi: { value: '', label: '' },
    tanggalRetensiAktif: null,
    tanggalRetensiInaktif: null,
    statusPenanganan: '',
    vitalNonVital: { value: '', label: '' },
    nomorRak: '',
    nomorFolder: '',
    nomorFillingCabinet: '',
    jumlahFolder: 0,
    tingkatPerkembangan: { value: '', label: '' },
    klasifikasiKeamanan: { value: '', label: '' },
    isActive: true,
    jumlahIsiBerkas: null,
    jumlahBerkasSatuan: null,
    jumlahBerkasSatuanOptions: [{ value: 'berkas', label: 'Berkas' }, { value: 'folder', label: 'Folder' }],

    jraKlasifikasiOptions: [],
    vitalNonVitalOptions: [{ value: 'vital', label: 'vital' }, { value: 'non-vital', label: 'non-vital' }],

    tingkatPerkembanganOptions: [],
    klasifikasiKeamananOptions: [],

    unitPengolah: null,
    unitPengolahOptions: []
  })

  useEffect(() => {
    // console.log(props.match);
    getUnitPengolah()
    getJRAoptions(props.token)
    getTingkatPerkembangan(props.token)
    getKlasifikasiKeamanan(props.token)

    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getUnitPengolah = () => {
    Axios.get('/unit-pengolah-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          unitPengolahOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getJRAoptions = (token) => {
    Axios.get('/unit-pengolah/jra-klasifikasi-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          jraKlasifikasiOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getTingkatPerkembangan = (token) => {
    Axios.get('/tingkat-perkembangan-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          tingkatPerkembanganOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getKlasifikasiKeamanan = (token) => {
    Axios.get('/klasifikasi-keamanan-list?format=select_options&is-active=true', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          klasifikasiKeamananOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (token, id) => {
    Axios.get(`/unit-kearsipan/arsip-unit-pengolah/daftar-berkas/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          uid: res.data.id,
          judul: res.data.judul,
          isActive: res.data.isActive,
          unitPengolah: _.isNull(res.data.unitPengolah)
            ? { value: '', label: '' }
            : {
                value: res.data.unitPengolah.id,
                label: `${res.data.unitKerja.name} / ${res.data.unitPengolah.name}`
              },
          jraKlasifikasi: _.isNull(res.data.jraKlasifikasi)
            ? { value: '', label: '' }
            : {
                value: res.data.jraKlasifikasi.id,
                label: `[${res.data.jraKlasifikasi.code}] ${res.data.jraKlasifikasi.name}`
              },
          tingkatPerkembangan: _.isUndefined(res.data.tingkatPerkembangan)
            ? { value: '', label: '' }
            : { value: res.data.tingkatPerkembangan.id, label: res.data.tingkatPerkembangan.name },
          klasifikasiKeamanan: _.isUndefined(res.data.klasifikasiKeamanan)
            ? { value: '', label: '' }
            : { value: res.data.klasifikasiKeamanan.id, label: res.data.klasifikasiKeamanan.name },
          vitalNonVital: _.isNull(res.data.vitalNonVital)
            ? { value: '', label: '' }
            : { value: res.data.vitalNonVital, label: res.data.vitalNonVital },
          nomorFillingCabinet: res.data.nomorFillingCabinet,
          nomorRak: res.data.nomorRak,
          nomorFolder: res.data.nomorFolder,
          jumlahFolder: res.data.jumlahFolder,
          jumlahBerkasSatuan: _.isNull(res.data.jumlahBerkasSatuan)
            ? null
            : { value: res.data.jumlahBerkasSatuan, label: _.upperFirst(res.data.jumlahBerkasSatuan) }
        }))

        if (!_.isEmpty(res.data.judul) && !_.isEmpty(res.data.nomorFolder)) {
          setState((prevProps) => ({
            ...prevProps,
            isMinimalReq: true
          }))
        }

        if (_.has(res.data, 'kurunWaktuMulai')) {
          setState((prevProps) => ({
            ...prevProps,
            kurunWaktuMulai: _.isNull(res.data.kurunWaktuMulai) ? '' : res.data.kurunWaktuMulai.substring(0, 10)
          }))
        }

        if (_.has(res.data, 'kurunWaktuSelesai')) {
          setState((prevProps) => ({
            ...prevProps,
            kurunWaktuSelesai: _.isNull(res.data.kurunWaktuSelesai) ? '' : res.data.kurunWaktuSelesai.substring(0, 10)
          }))
        }

        if (!_.isNull(res.data.jraKlasifikasi) && !_.isNull(res.data.kurunWaktuSelesai)) {
          const kurunWaktuSelesaiDate = parseISO(res.data.kurunWaktuSelesai)
          // console.log(format(kurunWaktuSelesaiDate, 'Y-MM-dd'))
          setupJraDetail(res.data.jraKlasifikasi.id, format(kurunWaktuSelesaiDate, 'Y-MM-dd'))
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeUnitPengolah = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      unitPengolah: obj
    }))
  }

  const handleChangeNomorFolder = (obj) => {
    const nomorFolder = obj.target.value
    let isMinimalReq = false

    if (!_.isEmpty(state.judul) && !_.isEmpty(nomorFolder)) {
      isMinimalReq = true
    }

    setState((prevProps) => ({
      ...prevProps,
      nomorFolder,
      isMinimalReq
    }))
  }

  const handleChangeJudul = (obj) => {
    const judul = obj.target.value
    let isMinimalReq = false

    if (!_.isEmpty(state.nomorFolder) && !_.isEmpty(judul)) {
      isMinimalReq = true
    }

    setState((prevProps) => ({
      ...prevProps,
      judul,
      isMinimalReq
    }))
  }

  const handleChangeKurunWaktuMulai = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      kurunWaktuMulai: obj.target.value
    }))
  }

  const handleChangeKurunWaktuSelesai = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      kurunWaktuSelesai: obj.target.value
    }))

    if (!_.isEqual(state.jraKlasifikasi.value, '') && _.isString(obj.target.value)) {
      setupJraDetail(state.jraKlasifikasi.value, obj.target.value)
    }
  }

  const handleChangeJraKlasifikasi = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      jraKlasifikasi: obj
    }))

    if (!_.isEqual(state.kurunWaktuSelesai, '')) {
      setupJraDetail(obj.value, state.kurunWaktuSelesai)
    }
  }

  const setupJraDetail = (jraKlasifikasiId, kurunWaktuSelesai) => {
    Axios.get(`/jra-klasifikasi/${jraKlasifikasiId}`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        function addYears (dt, n) {
          return new Date(dt.setFullYear(dt.getFullYear() + n))
        }

        const tanggalRetensiAktifObject = parseISO(kurunWaktuSelesai)
        const tanggalRetensiInaktifObject = parseISO(kurunWaktuSelesai)

        setState((prevProps) => ({
          ...prevProps,
          tanggalRetensiAktif: addYears(tanggalRetensiAktifObject, res.data.retensiAktif),
          tanggalRetensiInaktif: addYears(tanggalRetensiInaktifObject, res.data.retensiInaktif),
          statusPenanganan: res.data.statusPenanganan
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeTingkatPerkembangan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      tingkatPerkembangan: obj
    }))
  }

  const handleChangeKlasifikasiKeamanan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      klasifikasiKeamanan: obj
    }))
  }

  const handleChangeVitalNonVital = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      vitalNonVital: obj
    }))
  }

  const handleChangeNomorFillingCabinet = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      nomorFillingCabinet: obj.target.value
    }))
  }

  const handleChangeNomorRak = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      nomorRak: obj.target.value
    }))
  }

  const handleChangeJumlahBerkasSatuan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      jumlahBerkasSatuan: obj
    }))
  }

  const handleChangeIsActive = (isActive) => {
    setState((prevProps) => ({
      ...prevProps,
      isActive
    }))
  }

  const onSubmit = () => {
    // Toastr.info('Menyimpan data...');
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.uid) ? 'POST' : 'PATCH',
      url: _.isNull(state.uid) ? '/unit-kearsipan/arsip-unit-pengolah/daftar-berkas' : `/unit-kearsipan/arsip-unit-pengolah/daftar-berkas/${state.uid}`,
      data: {
        judul: state.judul,
        kurunWaktuMulai: state.kurunWaktuMulai,
        kurunWaktuSelesai: state.kurunWaktuSelesai,
        jraKlasifikasi: state.jraKlasifikasi.value,
        tingkatPerkembangan: state.tingkatPerkembangan.value,
        klasifikasiKeamanan: state.klasifikasiKeamanan.value,
        vitalNonVital: state.vitalNonVital.value,
        nomorFillingCabinet: state.nomorFillingCabinet,
        nomorRak: state.nomorRak,
        nomorFolder: state.nomorFolder,
        jumlahFolder: state.jumlahFolder,
        isActive: state.isActive ? 'true' : 'false',
        jumlahBerkasSatuan: _.isNull(state.jumlahBerkasSatuan) ? '' : state.jumlahBerkasSatuan.value
      }
    })
      .then(() => {
        // console.log(res.data)
        Toastr.success('Data berhasil diubah!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedOrUpdateData: true
        }))
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  if (state.isSavedOrUpdateData) {
    return <Redirect to={'/authorized/arsip-unit-pengolah/daftar-berkas'}/>
  }

  return (
    <>
          {/* { console.log(state) } */}
      <AuthorizedHeader2 icon={faFolderOpen} data={['arsip unit pengolah', 'daftar berkas']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-white border-0">
                <Link className="btn btn-primary" to="/authorized/arsip-unit-pengolah/daftar-berkas">
                  <i className="fas fa-bars"/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                {_.isNull(state.uid) ? null : <Alert color="warning">Anda akan mengubah data: <strong>{state.judul}</strong></Alert> }
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Unit Pengolah</Label>
                          <Select
                            isDisabled={true}
                            value={state.unitPengolah}
                            onChange={handleChangeUnitPengolah}
                            options={state.unitPengolahOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="clearfix"/>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Nomor Folder</Label>
                          <Input
                            minLength={3}
                            value={state.nomorFolder}
                            onChange={handleChangeNomorFolder}
                            placeholder="Nomor Folder"
                            type="text"
                          />
                          {state.nomorFolderExist
                            ? <FormText color={'danger'}>Maaf, nomor folder yang Anda masukkan sudah terpakai</FormText>
                            : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Judul Berkas</Label>
                          <Input
                            value={state.judul}
                            onChange={handleChangeJudul}
                            placeholder="Judul Berkas"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Kurun Waktu (Mulai)</Label>
                          <Input
                            value={state.kurunWaktuMulai}
                            onChange={handleChangeKurunWaktuMulai}
                            id="example-date-input"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Kurun Waktu (Selesai)</Label>
                          <Input
                            value={state.kurunWaktuSelesai}
                            onChange={handleChangeKurunWaktuSelesai}
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Klasifikasi Arsip (JRA)</Label>
                          <Select
                            value={state.jraKlasifikasi}
                            onChange={handleChangeJraKlasifikasi}
                            options={state.jraKlasifikasiOptions}
                          />
                          <Card>
                            <Table
                              className="text-blue"
                              bordered={true}>
                              <tbody>
                              <tr>
                                <td style={{ width: '100px' }}>Tanggal Retensi Aktif :</td>
                                <td>
                                  <DatetimeFormatV2 value={state.tanggalRetensiAktif}/>
                                </td>
                              </tr>
                              <tr>
                                <td>Tanggal Retensi Inaktif :</td>
                                <td>
                                  <DatetimeFormatV2 value={state.tanggalRetensiInaktif}/>
                                </td>
                              </tr>
                              <tr>
                                <td>Status Penanganan :</td>
                                <td>{_.toUpper(state.statusPenanganan)}</td>
                              </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Tingkat Perkembangan</Label>
                          <Select
                            value={state.tingkatPerkembangan}
                            onChange={handleChangeTingkatPerkembangan}
                            options={state.tingkatPerkembanganOptions}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Klasifikasi Keamanan</Label>
                          <Select
                            value={state.klasifikasiKeamanan}
                            onChange={handleChangeKlasifikasiKeamanan}
                            options={state.klasifikasiKeamananOptions}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Vital/Non-Vital</Label>
                          <Select
                            value={state.vitalNonVital}
                            onChange={handleChangeVitalNonVital}
                            options={state.vitalNonVitalOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <Label>Nomor Filing Cabinet</Label>
                          <Input
                            value={state.nomorFillingCabinet}
                            onChange={handleChangeNomorFillingCabinet}
                            placeholder="Nomor Filing Cabinet"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup>
                          <Label>Nomor Rak</Label>
                          <Input
                            value={state.nomorRak}
                            onChange={handleChangeNomorRak}
                            placeholder="Nomor Rak"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Jumlah Berkas (Satuan)</Label>
                          <Select
                            value={state.jumlahBerkasSatuan}
                            onChange={handleChangeJumlahBerkasSatuan}
                            options={state.jumlahBerkasSatuanOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <Label>Status Aktif : </Label>
                      <Switch
                        checked={state.isActive}
                        onChange={handleChangeIsActive}
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={!state.isMinimalReq}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(ArsipUnitPengolahDaftarBerkasForm)
